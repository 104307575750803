import React, { Component } from "react";
import { connect } from "react-redux";
import { updateBookingForm } from "../../Redux/Booking/bookingActions";
import { Helmet } from "react-helmet";
import "./LandingPage.css";

import SpecializedExperts from "./SpecializedExperts/SpecializedExperts";
import { ASSETS } from "../../Utils/Assets";
import TestimonialsPageView from "./Testimonials/TestimonialsSection";
import { Link } from "react-router-dom";
import PortableContactUs from "../ContactUs/PortableContactUs/PortableContactUs";

const actions = {
  updateBookingForm,
};

const mapState = (state) => {
  return {
    auth: state.auth,
  };
};

class LandingPage extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>PixelnBrush</title>
          <meta name="description" content="Website Description" />
        </Helmet>
        <FreelanceDesignBanner />
        <br />
        <SpecializedExperts />
        <br />
        <TestimonialsPageView />
        <PortableContactUs/>
      </div>
    );
  }
}

export default connect(mapState, actions)(LandingPage);

const FreelanceDesignBanner = () => (
  <div className="freelance-design-banner">
    <div className="banner-content">
      <h1>Hire freelance design and creative experts to help you scale</h1>
      <p>
        Thousands of pros ready to kick-start your creative overhaul from day
        one.
      </p>
      <Link to={"/hire-designer"}>
        <button className="get-started-button">Get started</button>
      </Link>
    </div>
    <div className="banner-image">
      <img src={ASSETS.HIRE_DESIGNER} alt="Freelance Designer" />
    </div>
  </div>
);
