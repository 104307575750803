import React, { useState } from "react";
import { Form, Button, Message } from "semantic-ui-react";
import { AccentButton } from "../../Custom/CustomButtons";

const Page2 = ({ onNext, onPrev, data, setData }) => {
  const [companyDescription, setCompanyDescription] = useState(
    data["companyDescription"] ?? ""
  );
  const [errors, setErrors] = useState({});

  const handleNext = () => {
    const errors = validatePage2();
    if (Object.keys(errors).length === 0) {
      setData({
        companyDescription: companyDescription,
      });
      onNext();
    } else {
      setErrors(errors);
    }
  };

  const validatePage2 = () => {
    const errors = {};
    if (!companyDescription)
      errors.companyDescription = "Company Description is required";
    return errors;
  };

  return (
    <Form>
      <Form.TextArea
        label="Company Description"
        value={companyDescription}
        onChange={(e) => setCompanyDescription(e.target.value)}
        error={errors.companyDescription ? true : false}
      />
      <div style={{textAlign:"right"}}>
        <AccentButton
          
          inverted
          text="Previous"
          onClick={onPrev}
        />
        <AccentButton  text="Next" onClick={handleNext} />
      </div>
      {Object.keys(errors).length > 0 && (
        <Message error header="Validation Error" list={Object.values(errors)} />
      )}
    </Form>
  );
};

export default Page2;
