import React from "react";
import { Card, Grid, Icon, Segment } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "./WhyChooseUs.css"; // Make sure to have this CSS for styling

const WhyChooseUs = () => {
  const benefits = [
    {
      header: "Innovative Projects",
      description:
        "Dive into a wide array of projects that push the boundaries of design. From reimagining user interfaces for leading tech companies to crafting the visual identity for emerging brands, you’ll have the opportunity to shape the future of digital and physical spaces.",
      iconName: "lightbulb",
    },
    {
      header: "Creative Freedom",
      description:
        "We believe in the power of ideas and encourage every designer to bring their unique perspective to the table. Here, your voice matters. You’ll have the autonomy to explore creative solutions and the support to bring your most ambitious ideas to life.",
      iconName: "pencil alternate",
    },
    {
      header: "Community & Support",
      description:
        "Join a community of passionate designers who are as eager to share their knowledge as they are to learn. Our supportive network includes mentorship programs, regular workshops, and team collaborations, ensuring you’re never alone on your creative journey.",
      iconName: "users",
    },
    {
      header: "Competitive Compensation",
      description:
        "Your skill and dedication deserve recognition. We offer competitive compensation packages, including performance bonuses, comprehensive health benefits, and opportunities for professional development. Invest in your creativity and well-being as part of our team.",
      iconName: "dollar sign",
    },
  ];

  return (
    <Segment padded basic className="whyChooseUsContainer" style={{padding:"4rem"}}>
      <div className="title-large">Craft Your Future With Us</div>
      <div className="medium-text">Where Creativity Meets Opportunity</div>
      <br/>
      <br/>
      <br/>
      <Grid container stackable centered>
        <Grid.Row>
          {benefits.map((benefit, index) => (
            <Grid.Column key={index} width={4} className="benefitColumn">
              <Card
                className="benefitCard"
                header={benefit.header}
                description={benefit.description}
                icon={
                  <Icon
                    name={benefit.iconName}
                    size="large"
                    className="benefitIcon"
                  />
                }
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default WhyChooseUs;
