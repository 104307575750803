import React, { Component, Fragment } from "react";
import {
  Menu,
  Image,
  Dropdown,
  Segment,
  Button,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { signOut } from "../../Redux/Auth/authActions";
import { connect } from "react-redux";
import "./NavbarStyle.css";
import { openModal } from "../../Redux/Modals/ModalActions";
import {
  BACKGROUND_COLOR,
  PRIMARY_COLOR,
} from "../../Utils/Constants";
import { MODAL_LOGIN_REGISTER } from "../Modals/Auth/LoginRegisterModal";

const HOME = "home";
const FLEET = "fleet";
const CONTACT = "contact";
const CAREER = "career";
const ABOUT = "about";

function MenuItems(activeItem, changeActiveItem) {
  return (
    <div
      style={{
        padding: "0rem 10rem",
        backgroundColor: BACKGROUND_COLOR,
        position: "relative",
        textAlign: "center",
      }}
    >
      <Menu
        style={{
          marginTop: "0px",
          marginBottom: "0px",
          textAlign: "center",
          border: "none",
        }}
        compact
      >
        <Dropdown text="Service" className="link item">
          <Dropdown.Menu>
            <Dropdown.Item
              key={"hire-a-designer"}
              as={Link}
              to={"/hire-designer"}
            >
              Hire a Designer
            </Dropdown.Item>
            <Dropdown.Item
              key={"become-a-designer"}
              as={Link}
              to={"/become-designer"}
            >
              Become a Designer
            </Dropdown.Item>
            {/* {SERVICES.map((service) => (
                    <Dropdown.Item key={service.to} as={Link} to={service.to}>
                      {service.title}
                    </Dropdown.Item>
                  ))} */}
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item
          as={Link}
          to={"/about"}
          onClick={() => {
            this.changeActiveItem(ABOUT);
          }}
          active={activeItem === ABOUT}
        >
          About Us
        </Menu.Item>
        <Menu.Item
          as={Link}
          to={"/contact"}
          onClick={() => {
            this.changeActiveItem(CONTACT);
          }}
          active={activeItem === CONTACT}
        >
          Contact Us
        </Menu.Item>
      </Menu>
    </div>
  );
}

const mapStateToProp = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

const actions = {
  signOut,
  openModal,
};

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.reference = null;
    this.runIndex = 0;
  }

  state = {
    activeItem: FLEET,
    openSubmit: false,
  };

  componentDidMount() {
    this.reference = setInterval(() => {
      this.setState({
        openSubmit: !this.state.openSubmit,
      });
      ++this.runIndex;
      if (this.runIndex === 2) {
        clearInterval(this.reference);
        this.reference = null;
      }
    }, 20000);
  }

  changeActiveItem = (item) => () => {
    this.setState({
      activeItem: item,
    });
  };
  decideBackground = () => {
    switch (this.props.logoSize) {
      case "tiny":
        return { backgroundColor: "orange" };
      case "medium":
        return { background: "transparent" };
      default:
        return { backgroundColor: "none" };
    }
  };

  decodeLogoPosition = () => {
    switch (this.props.logoSize) {
      case "tiny":
        return "center";
      case "medium":
        return true;
      default:
        return true;
    }
  };

  decideLogo = () => {
    switch (this.props.logoSize) {
      case "tiny":
        return false;
      case "small":
        return true;
      default:
        return true;
    }
  };

  render() {
    const { activeItem } = this.state;
    const { auth, openModal } = this.props;
    const authenticated = !auth.isEmpty && auth.isLoaded;

    return (
      <div
        className="top-navigation-bar"
        style={{
          width: "100vw",
          padding: "0rem 1.5rem",
          backgroundColor: BACKGROUND_COLOR,
          position: "relative",
          textAlign: "center",
        }}
      >
        {this.props.showLogo && (
          <Menu
            secondary
            style={{
              marginTop: "0px",
              marginBottom: "0px",
              width: "100%",
              border: "none",
            }}
          >
            <Menu.Item as={Link} to="/">
              <Image src="/logo.png" style={{ height: "4rem" }} />
              <div
                className="title-large"
                style={{ color: PRIMARY_COLOR }}
              >
                PixelnBrush
              </div>
            </Menu.Item>

            <Menu.Menu
              position="right"
              secondary="true"
              pointing="true"
              style={{ padding: "1% 1%" }}
            >
              <Dropdown text="Service" className="link item">
                <Dropdown.Menu>
                  <Dropdown.Item
                    key={"hire-a-designer"}
                    as={Link}
                    to={"/hire-designer"}
                  >
                    Hire a Designer
                  </Dropdown.Item>
                  <Dropdown.Item
                    key={"become-a-designer"}
                    as={Link}
                    to={"/become-designer"}
                  >
                    Become a Designer
                  </Dropdown.Item>
                  {/* {SERVICES.map((service) => (
                    <Dropdown.Item key={service.to} as={Link} to={service.to}>
                      {service.title}
                    </Dropdown.Item>
                  ))} */}
                </Dropdown.Menu>
              </Dropdown>
              <Menu.Item
                as={Link}
                to={"/about"}
                onClick={this.changeActiveItem(ABOUT)}
                active={activeItem === ABOUT}
              >
                About Us
              </Menu.Item>
              <Menu.Item
                as={Link}
                to={"/contact"}
                onClick={this.changeActiveItem(CONTACT)}
                active={activeItem === CONTACT}
              >
                Contact Us
              </Menu.Item>
              {authenticated && (
                <Dropdown item text="Account">
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/account/orders">
                      My Account
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => this.props.signOut()}>
                      Log Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {!authenticated && (
                <Fragment>
                  <Menu.Item style={{ marginRight: "0px", marginLeft: "0px" }}>
                    <Button
                      basic
                      onClick={() => {
                        openModal(MODAL_LOGIN_REGISTER, { forLogin: true });
                      }}
                      content={"Log In"}
                    />
                  </Menu.Item>
                  <Menu.Item style={{ marginRight: "0px", marginLeft: "0px" }}>
                    <Dropdown
                      text={<div style={{ color: "white" }}>Register</div>}
                      button
                      style={{ background: PRIMARY_COLOR, color: "white" }}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          key={"hire-a-designer"}
                          as={Link}
                          to={"/hire-designer"}
                        >
                          As a Brand
                        </Dropdown.Item>
                        <Dropdown.Item
                          key={"become-a-designer"}
                          as={Link}
                          to={"/become-designer"}
                        >
                          As a Designer
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu.Item>
                </Fragment>
              )}
            </Menu.Menu>
          </Menu>
        )}
        {!this.props.showLogo && (
          <Segment basic>
            {" "}
            {MenuItems(activeItem, this.changeActiveItem)}
          </Segment>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProp, actions)(Navbar);
