import randomstring from "randomstring";
import { BOOKING_STATUS, BOOKING_TYPE, RIDE_TYPE } from "./enums";


export const PRIMARY_COLOR = "#800020";
export const ON_PRIMARY_COLOR = "#FFFFFF";
export const BACKGROUND_COLOR = "#FFFFFF";
export const SECONDARY_BACKGROUND_COLOR= "#FFCBA422";
export const HIGHLIGHT_COLOR = "#FF6F61";

export const EMPTY_TRIP = {
  start: {
    name: "",
    position: {
      lat: 0.0,
      lon: 0.0,
    },
  },
  end: {
    name: "",
    position: {
      lat: 0.0,
      lon: 0.0,
    },
  },
};

export const EMPTY_BOOKING_DATA = {
  current: {
    id: "",
    customer: null,
    pickupTime: "",
    pickupDate: "",
    trip: EMPTY_TRIP,
    bookingType: BOOKING_TYPE.ONE_WAY,
    status: BOOKING_STATUS.processing,
    returnDate: "nill",
    returnTime: "nill",
    betweenDays: 0,
    serviceType: RIDE_TYPE.SEDAN,
    customerMessage: "",
    travelDistance: 0,
    estimatedAmount: 0,
    passangers: {
      primary: {
        fullName: "",
        email: "",
        phoneNumber: "",
      },
    },
    numberOfPassangers: 0,
  },
  loading: false,
};


export const SERVICES = Object.values({}).map((data)=> {
  return {title:data.name,to:`/services/${data.serviceId}`};
});

export const USERS_TYPE = [
  "Brand",
  "Manufacturer",
  "Designer",
].map((value) => {
  return generateDropdownOptions(value);
});

export const BOOKING_TYPE_DROPDOWN_OPTIONS = [
  { key: BOOKING_TYPE.ONE_WAY, value: BOOKING_TYPE.ONE_WAY, text: "One Way" },
  {
    key: BOOKING_TYPE.ROUND_TRIP,
    value: BOOKING_TYPE.ROUND_TRIP,
    text: "Round Trip",
  },
  {
    key: BOOKING_TYPE.AIRPORT_RIDE,
    value: BOOKING_TYPE.AIRPORT_RIDE,
    text: "Airport Ride",
  },
];

function generateDropdownOptions(optionValue) {
  return { key: optionValue, value: optionValue, text: optionValue };
}

export const FEEBACK_CATEGORIES = ["Complaint", "Feedback", "Refund"];

export const GENDER_CHOICES = [
  { key: "male", value: "Male", text: "Male" },
  { key: "female", value: "Female", text: "Female" },
];

export const DEPENDENT_CHILDREN = [
  { key: "no", value: "no", text: "No, I Don't have any!" },
  { key: "1", value: "1", text: "1" },
  { key: "2", value: "2", text: "2" },
  { key: "3", value: "3", text: "3" },
  { key: "4", value: "4", text: "4" },
  { key: "5", value: "5", text: "5" },
  { key: "6", value: "6", text: "6" },
  { key: "7", value: "7", text: "7" },
  { key: "8", value: "8", text: "8" },
  { key: "9", value: "9", text: "9" },
];

export const FAMILY_MEMBER_COUNT = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
  (number) => {
    return generateDropdownOptions(number);
  }
);

export const VISA_CHOICES = [
  { key: "1", value: "Visitor Visa", text: "Visitor Visa" },
  // { key: "2", value: "Work Visa", text: "Work Visa" },
  // { key: "3", value: "Study Visa", text: "Study Visa" }
];

export const CURRENCY_OPTIONS = [
  { key: "INR", value: "INR", text: "INR" },
  { key: "USD", value: "USD", text: "USD" },
  { key: "CAD", value: "CAD", text: "CAD" },
  { key: "AUD", value: "AUD", text: "AUD" },
];

export const DECISION_MAKING_STAGE = [
  {
    key: "ready",
    value: "Ready to start Application",
    text: "Ready to Start Application",
  },
  {
    key: "collectingInfo",
    value: "Just Collecting Information",
    text: "Just Collecting Information",
  },
];

export const MARITAL_STATUS = [
  { key: "single", value: "Single", text: "Single" },
  { key: "married", value: "Married", text: "Married" },
  { key: "civil_marriage", value: "Civil Marriage", text: "Civil Marriage" },
  { key: "divorced", value: "Divorced", text: "Divorced" },
  { key: "widowed", value: "Widowed", text: "Widowed" },
];

export const APPLICATION_START_TIME = [
  {
    key: "Immediately",
    value: "Immediately",
    text: "Immediately",
  },
  {
    key: "3m",
    value: "Next 3 Months",
    text: "Next 3 Months",
  },
  {
    key: "612m",
    value: "Next 6-12 Months",
    text: "Next 6-12 Months",
  },
  {
    key: "np",
    value: "Not Planning",
    text: "Not Planning",
  },
];

export const DEVICE_OPTIONS = [
  {
    key: "laptop",
    value: "Laptop",
    text: "Laptop",
  },
  {
    key: "android_phone",
    value: "Android Phone",
    text: "Android Phone",
  },
  {
    key: "iPhone",
    value: "iPhone",
    text: "iPhone",
  },
];

export const getCoatingStringFromArray = (data) => {
  let value = "";
  data.forEach((val) => {
    value = value + "," + val;
  });
  return value;
};

export const createIntegerDropdownOptions = (start, end, step) => {
  let data = [];
  for (let index = start; index <= end; index = index + step) {
    data.push({ key: index, value: index, text: index.toString() });
  }
  return data;
};

export const getDateStringFromMills = (mills) => {
  let date = new Date(mills);
  return (
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );
};

export const getFileExtension = (oldName, prefix) => {
  let newName = "";
  if (oldName.endsWith(".jpg")) {
    return ".jpg";
  } else if (oldName.endsWith(".jpeg")) {
    return ".jpeg";
  } else if (oldName.endsWith(".docx")) {
    return ".docx";
  } else if (oldName.endsWith(".doc")) {
    return ".doc";
  } else if (oldName.endsWith(".pdf")) {
    return ".pdf";
  }

  return newName;
};

export const getFileNameForUpload = (oldName, prefix) => {
  let newName = prefix + getFileExtension(oldName);

  return newName;
};

export const getUniqueID = (length) => {
  return randomstring.generate({
    length: length,
    charset: "alphanumeric",
    capitalization: "uppercase",
  });
};

export const getUniqueNumericID = (length) => {
  return randomstring.generate({
    length: length,
    charset: "numeric",
  });
};

export const FILE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
];

export const BRAND_NAME = "PixelnBrush";
export const SUPPORT_EMAIL = "info@pixelnbrush.com";
export const SUPPORT_CONTACT = "8287320882";
export const SUPPORT_CONTACT_TEXT = "8287320882";
