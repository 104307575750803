import React, { Component } from "react";
import {
  Grid,
  List,
  Header,
  Icon,
  Segment,
  Image,
  
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { ASSETS } from "../../Utils/Assets";
import {
  BACKGROUND_COLOR,
  SUPPORT_CONTACT,
  SUPPORT_CONTACT_TEXT,
  SUPPORT_EMAIL,
} from "../../Utils/Constants";
import { PRODUCTION, PROJECT_MODE } from "../../Utils/Controller";

export default class Footer extends Component {
  render() {
    return (
      <div>
        <Segment
          basic
          style={{
            margin: "0px 0px",
            padding: "0px 0px",

            backgroundColor: BACKGROUND_COLOR,
          }}
        >
          <div style={{ padding: "1% 1%" }}>
            <Grid padded stackable columns="equal" centered>
              <Grid.Column
                mobile="16"
                tablet="16"
                computer="5"
                verticalAlign="middle"
                textAlign="left"
              >
                <Header as="h1" size="huge">
                  <Image circular src={ASSETS.LOGO} /> PixelnBrush
                </Header>
                <br/>
                <List verticalAlign="middle">
                  <List.Item style={{ marginBottom: "1rem" }}>
                    {/* <Icon name="mail" /> */}
                    <List.Content to="/contact">
                      <Icon disabled name="mail" inverted />
                      <a href={`mailto:${SUPPORT_EMAIL}`}>
                        {SUPPORT_EMAIL}
                      </a>{" "}
                      <br />
                    </List.Content>
                  </List.Item>

                  <List.Item style={{ marginBottom: "1rem" }}>
                    {/* <Icon name="call" /> */}
                    <List.Content>
                      <Icon disabled name="call" inverted />
                      <a href={`tel:${SUPPORT_CONTACT}`}>
                        {SUPPORT_CONTACT_TEXT}
                      </a>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column mobile="16" tablet="16" computer="5">
                <p className="title-large">Services Range</p>
                <List animated selection verticalAlign="middle">
                  <List.Item key={"hire-designer"}>
                    <List.Content as={Link} to="/hire-designer">
                      <List.Header>Hire a Designer</List.Header>
                    </List.Content>
                  </List.Item>
                  <List.Item key={"become-designer"}>
                    <List.Content as={Link} to="/become-designer">
                      <List.Header>Become a Designer</List.Header>
                    </List.Content>
                  </List.Item>
                  <List.Item key={"contact"}>
                    <List.Content as={Link} to="/contact">
                      <List.Header>Contact Us</List.Header>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column mobile="16" tablet="16" computer="5">
                <p className="title-large">Navigation</p>
                <List animated selection verticalAlign="middle">
                  <List.Item key={"home"}>
                    <List.Content as={Link} to="/">
                      <List.Header>Home</List.Header>
                    </List.Content>
                  </List.Item>
                  <List.Item key={"contact"}>
                    <List.Content as={Link} to="/contact">
                      <List.Header>Contact Us</List.Header>
                    </List.Content>
                  </List.Item>
                  <List.Item key={"about"}>
                    <List.Content as={Link} to="/about">
                      <List.Header>Know About Us?</List.Header>
                    </List.Content>
                  </List.Item>
                  <List.Item key={"terms-and-conditions"}>
                    <List.Content as={Link} to="/terms-and-conditions">
                      <List.Header>Terms and Conditions</List.Header>
                    </List.Content>
                  </List.Item>
                  <List.Item key={"privacy-policy"}>
                    <List.Content as={Link} to="/privacy-policy">
                      <List.Header>Privacy Policy</List.Header>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
          </div>

          <Segment
            basic
            padded
            textAlign="center"
            style={{
              backgroundColor: "white",
              margin: "0px 0px",
            }}
          >
            <Header as="h4" textAlign="center" style={{ lineHeight: "2em" }}>
              Copyright© PixelnBrush [2023]. All Rights Reserved <br />{" "}
              <p style={{ fontWeight: "lighter", fontSize: "0.9em" }}>
                Powered By PixelnBrush (Version 1.0.1){" "}
                {PROJECT_MODE == PRODUCTION ? "" : "DEV"}
              </p>
            </Header>
          </Segment>
        </Segment>
      </div>
    );
  }
}
