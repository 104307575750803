import React, { useState } from "react";
import { Form, Button, Message } from "semantic-ui-react";
import { AccentButton } from "../../Custom/CustomButtons";

const Page3 = ({ onPrev, onSubmit, data }) => {
  const [projectDetails, setProjectDetails] = useState(
    data["projectDetails"] ?? ""
  );
  const [budget, setBudget] = useState(data["budget"] ?? "");
  const [designPreferences, setDesignPreferences] = useState(
    data["designPreferences"] ?? ""
  );
  const [errors, setErrors] = useState({});

  const handleSubmit = () => {
    const errors = validatePage3();
    if (Object.keys(errors).length === 0) {
      onSubmit({
        projectDetails: projectDetails,
        budget: budget,
        designPreferences: designPreferences,
      });
    } else {
      setErrors(errors);
    }
  };

  const validatePage3 = () => {
    const errors = {};
    if (!projectDetails) errors.projectDetails = "Project Details are required";
    return errors;
  };

  return (
    <Form>
      <Form.TextArea
        label="Project Details"
        value={projectDetails}
        onChange={(e) => setProjectDetails(e.target.value)}
        error={errors.projectDetails ? true : false}
      />
      <Form.Input
        label="Budget (Optional)"
        value={budget}
        onChange={(e) => setBudget(e.target.value)}
      />
      <Form.TextArea
        label="Design Preferences (Optional)"
        value={designPreferences}
        onChange={(e) => setDesignPreferences(e.target.value)}
      />
      <div style={{textAlign:"right"}}>
        <AccentButton
          
          inverted
          text="Previous"
          onClick={onPrev}
        />
        <AccentButton text="Submit" onClick={handleSubmit} />
      </div>

      {Object.keys(errors).length > 0 && (
        <Message error header="Validation Error" list={Object.values(errors)} />
      )}
    </Form>
  );
};

export default Page3;
