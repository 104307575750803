import { PROJECT_MODE, TESTING, STAGING, PRODUCTION } from "./Controller";
const URL_PREFIX_TESTING = "https://asia-south1-designhq-sourcenet-test.cloudfunctions.net/v1";
const URL_PREFIX_STAGING = "https://asia-south1-designhq-sourcenet-test.cloudfunctions.net/v1";
const URL_PREFIX_PRODUCTION = "https://asia-south1-designhq-sourcenet.cloudfunctions.net/v1";

let URL_PREFIX = TESTING;
switch (PROJECT_MODE) {
  case TESTING:
    URL_PREFIX = URL_PREFIX_TESTING;
    break;
  case STAGING:
    URL_PREFIX = URL_PREFIX_STAGING;
    break;
  case PRODUCTION:
    URL_PREFIX = URL_PREFIX_PRODUCTION;
    break;
  default:
    URL_PREFIX = URL_PREFIX_TESTING;
    break;
}

export const AUTH_REGISTER_AS_BRAND = URL_PREFIX + "/auth/register_as_brand";
export const AUTH_REGISTER_AS_DESIGNER = URL_PREFIX + "/auth/register_as_designer";
export const QUERY_MAIL_URL = URL_PREFIX + "/misc/query";
export const USER_EXISTS= URL_PREFIX + "";
