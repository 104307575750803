import React, { Component } from "react";
import "./HireADesigner.css"; // Make sure to create this CSS file
import { ASSETS } from "../../Utils/Assets";
import {
  Card,
  Container,
  Grid,
  Icon,
  Image,
  List,
  ListItem,
  Segment,
} from "semantic-ui-react";
import { SECONDARY_BACKGROUND_COLOR } from "../../Utils/Constants";
import FormWithAnimation from "./FormWithAnimations";
import FAQSection from "./FAQSection";
import PortableContactUs from "../ContactUs/PortableContactUs/PortableContactUs";

function HireADesigner() {
  return (
    <div>
      <FormWithAnimation />
      <Segment
        padded
        style={{
          backgroundColor: SECONDARY_BACKGROUND_COLOR,
          width: "100%",
          marginTop: "0px",
        }}
      >
        <div className="pixelnbrush-container">
          <div className="header-section">
            <h1>Why Hire Designers from PixelnBrush?</h1>
            <p>
              Brands and Manufacturers hire designers from us as we have a track
              record of satisfactory project delivery, for all kinds of
              projects.
            </p>
          </div>
          <br />
          <br />
          <Grid>
            <Grid.Column width={10} verticalAlign="middle">
              <Grid columns="equal" relaxed>
                <Grid.Column verticalAlign="middle">
                  <List relaxed size="medium">
                    <ListItem
                      icon={<Icon name="users" size="large" />}
                      content="Elite design expertise"
                    />
                    <ListItem
                      icon={<Icon name="users" size="large" />}
                      content="Streamlined hiring process"
                    />
                    <ListItem
                      icon={<Icon name="users" size="large" />}
                      content="Tailored creative solutions"
                    />
                    <ListItem
                      icon={<Icon name="users" size="large" />}
                      content="Cutting-edge innovation"
                    />
                  </List>
                </Grid.Column>
                <Grid.Column verticalAlign="middle">
                  <List relaxed>
                    <ListItem
                      icon={<Icon name="users" size="large" />}
                      content="On-time project delivery"
                    />
                    <ListItem
                      icon={<Icon name="users" size="large" />}
                      content="Affordable rates"
                    />
                    <ListItem
                      icon={<Icon name="users" size="large" />}
                      content="Exceptional client service"
                    />
                    <ListItem
                      icon={<Icon name="users" size="large" />}
                      content="Proven track record"
                    />
                  </List>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column width={6}>
              <Image
                src={ASSETS.HIRE_DESIGNER}
                alt="Office Environment"
                rounded
              />
            </Grid.Column>
          </Grid>
          <br />

          <div className="stats-section">
            <div className="stat">
              <span className="number">18+</span>
              <br />
              <span className="text">Years in Business</span>
            </div>
            <div className="stat">
              <span className="number">650+</span>
              <br />
              <span className="text">Designers</span>
            </div>
            <div className="stat">
              <span className="number">2000+</span>
              <br />
              <span className="text">Man Years Experience</span>
            </div>
            <div className="stat">
              <span className="number">2500+</span>
              <br />
              <span className="text">Satisfied Customers</span>
            </div>
          </div>
          <br />
          <div className="consultation-section">
            <button className="contact-button">CONTACT US NOW</button>
          </div>
        </div>
      </Segment>
      <ChooseFromHiringModels />
      <FAQSection/>
      <PortableContactUs/>
    </div>
  );
}

export default HireADesigner;

function ChooseFromHiringModels() {
  return (
    <Segment padded basic style={{ padding: "2rem" }}>
      <div>
        <div style={{ textAlign: "center" }}>
          <div className="title-large">Choose From Our Hiring Models</div>
          <div className="medium-text">
            With us, you can choose from multiple hiring models that best suit
            your needs.
          </div>
        </div>
        <br />
        <Grid padded centered stretched stackable>
          <Grid.Column width={4}>
            <Card fluid>
              <Card.Content>
                <Icon name="group" size="huge" color="orange" />

                <div
                  className="title-medium"
                  style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
                >
                  Dedicated Team
                </div>

                <Container textAlign="justified">
                  It is an expert autonomous team comprising of different roles
                  (e.g. project manager, software engineers, QA engineers, and
                  other roles) capable of delivering technology solutions
                  rapidly and efficiently. The roles are defined for each
                  specific project and management is conducted jointly by a
                  Scrum Master and the client's product owner.
                </Container>
                <List relaxed>
                  <List.Item>
                    <List.Icon name="check circle" color="orange" />
                    <List.Content>Agile processes </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="check circle" color="orange" />
                    <List.Content>Transparent pricing</List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="check circle" color="orange" />
                    <List.Content>Monthly billing</List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="check circle" color="orange" />
                    <List.Content>Maximum flexibility</List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="check circle" color="orange" />
                    <List.Content>product companies</List.Content>
                  </List.Item>
                </List>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column width={4}>
            <Card fluid>
              <Card.Content>
                <Icon name="group" size="huge" color="orange" />

                <div
                  className="title-medium"
                  style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
                >
                  Team Augmentation
                </div>

                <Container textAlign="justified">
                  It is an expert autonomous team comprising of different roles
                  (e.g. project manager, software engineers, QA engineers, and
                  other roles) capable of delivering technology solutions
                  rapidly and efficiently. The roles are defined for each
                  specific project and management is conducted jointly by a
                  Scrum Master and the client's product owner.
                </Container>
                <List relaxed>
                  <List.Item>
                    <List.Icon name="check circle" color="orange" />
                    <List.Content>Agile processes </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="check circle" color="orange" />
                    <List.Content>Transparent pricing</List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="check circle" color="orange" />
                    <List.Content>Monthly billing</List.Content>
                  </List.Item>
                </List>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column width={4}>
            <Card fluid>
              <Card.Content>
                <Icon name="group" size="huge" color="orange" />

                <div
                  className="title-medium"
                  style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
                >
                  Project Based
                </div>
                <div className="title-small">Fixed Price Model:</div>
                <Container textAlign="justified">
                  When project specifications, scope, deliverables and
                  acceptance criteria are clearly defined, we can evaluate and
                  offer a fixed quote for the project. This is mostly suitable
                  for small-mid scale projects with well documented
                  specifications.
                </Container>
                <br />
                <div className="title-small">Fixed Price Model:</div>
                <Container textAlign="justified">
                  Suitable for projects that have undefined or dynamic scope
                  requirements or complicated business requirements due to which
                  the cost estimation is not possible. Therefore, developers can
                  be hired per their time.
                </Container>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </div>
    </Segment>
  );
}
