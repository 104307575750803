import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "semantic-ui-react";

export default function TermsAndConditions() {
  return (
    <div style={{ display: "block", textAlign: "center" }}>
      <Helmet>
        <title>Terms and Conditions - PixelnBrush</title>
        <meta
          name="description"
          content="PixelnBrush: Streamline your brand's creative scale-up by hiring top freelance design and creative experts. Discover a curated community of professional designers ready to elevate your projects. Whether you're a brand looking to hire or a designer seeking opportunities, register with us and experience seamless collaboration and growth."
        />
      </Helmet>
      <Container textAlign="left" style={{ padding: "2rem" }}>
        <div className="title-large" style={{ textAlign: "center" }}>
          Terms and Conditions - PixelnBrush
        </div>
        <br />
        <p class="medium-text">
          Welcome to PixelnBrush. These terms and conditions outline the rules and
          regulations for the use of PixelnBrush's Website, located at
          www.pixelnbrush.com.
        </p>
        <p class="medium-text">
          By accessing this website we assume you accept these terms and
          conditions. Do not continue to use PixelnBrush if you do not agree to
          take all of the terms and conditions stated on this page.
        </p>

        <h2 class="title-medium">Cookies</h2>
        <p class="medium-text">
          The website uses cookies to help personalize your online experience.
          By accessing PixelnBrush, you agreed to use the required cookies.
        </p>

        <h2 class="title-medium">License</h2>
        <p class="medium-text">
          Unless otherwise stated, PixelnBrush and/or its licensors own the
          intellectual property rights for all material on PixelnBrush. All
          intellectual property rights are reserved. You may access this from
          PixelnBrush for your own personal use subjected to restrictions set in
          these terms and conditions.
        </p>

        <h2 class="title-medium">You must not:</h2>
        <ul class="medium-text">
          <li>Republish material from PixelnBrush</li>
          <li>Sell, rent or sub-license material from PixelnBrush</li>
          <li>Reproduce, duplicate or copy material from PixelnBrush</li>
          <li>Redistribute content from PixelnBrush</li>
        </ul>

        <h2 class="title-medium">
          This Agreement shall begin on the date hereof.
        </h2>
        <p class="medium-text">
          Parts of this website offer an opportunity for users to post and
          exchange opinions and information in certain areas of the website.
          PixelnBrush does not filter, edit, publish or review Comments prior to
          their presence on the website. Comments do not reflect the views and
          opinions of PixelnBrush,its agents and/or affiliates. Comments reflect
          the views and opinions of the person who post their views and
          opinions.
        </p>

        <h2 class="title-medium">Hyperlinking to our Content</h2>
        <p class="medium-text">
          The following organizations may link to our Website without prior
          written approval:
          <ul>
            <li>Government agencies;</li>
            <li>Search engines;</li>
            <li>News organizations;</li>
            <li>
              Online directory distributors may link to our Website in the same
              manner as they hyperlink to the Websites of other listed
              businesses; and
            </li>
            <li>
              System wide Accredited Businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Web site.
            </li>
          </ul>
        </p>

        <h2 class="title-medium">iFrames</h2>
        <p class="medium-text">
          Without prior approval and written permission, you may not create
          frames around our Webpages that alter in any way the visual
          presentation or appearance of our Website.
        </p>

        <h2 class="title-medium">Content Liability</h2>
        <p class="medium-text">
          We shall not be hold responsible for any content that appears on your
          Website. You agree to protect and defend us against all claims that is
          rising on your Website. No link(s) should appear on any Website that
          may be interpreted as libelous, obscene or criminal, or which
          infringes, otherwise violates, or advocates the infringement or other
          violation of, any third party rights.
        </p>

        <h2 class="title-medium">Your Privacy</h2>
        <p class="medium-text">Please read Privacy Policy.</p>

        <h2 class="title-medium">Reservation of Rights</h2>
        <p class="medium-text">
          We reserve the right to request that you remove all links or any
          particular link to our Website. You approve to immediately remove all
          links to our Website upon request. We also reserve the right to amen
          these terms and conditions and it’s linking policy at any time. By
          continuously linking to our Website, you agree to be bound to and
          follow these linking terms and conditions.
        </p>

        <h2 class="title-medium">Removal of links from our website</h2>
        <p class="medium-text">
          If you find any link on our Website that is offensive for any reason,
          you are free to contact and inform us any moment. We will consider
          requests to remove links but we are not obligated to or so or to
          respond to you directly.
        </p>

        <h2 class="title-medium">Disclaimer</h2>
        <p class="medium-text">
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties and conditions relating to our website and
          the use of this website. Nothing in this disclaimer will:
          <ul>
            <li>
              limit or exclude our or your liability for death or personal
              injury;
            </li>
            <li>
              limit or exclude our or your liability for fraud or fraudulent
              misrepresentation;
            </li>
            <li>
              limit any of our or your liabilities in any way that is not
              permitted under applicable law; or
            </li>
            <li>
              exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </li>
          </ul>
        </p>
      </Container>
    </div>
  );
}
