// React Component File: TestimonialsPageView.js

import React, { useState } from "react";
import "./TestimonialsSection.css"; // make sure to have this CSS file in the same directory
import { ASSETS } from "../../../Utils/Assets";
import { Divider, Segment } from "semantic-ui-react";

const testimonials = [
  // First Page of Testimonials
  [
    {
      quote:
        "PixelnBrush connected me with top designers who brought my vision to life. Their expertise transformed my brand's identity. Highly recommend!",
      author: "Anjali Sharma",
      position: "CTO, Tech Innovations Inc.",
      rating: 5,
      avatar: ASSETS.USER_1, // Replace with actual avatar image path
    },
    {
      quote:
        "Working with PixelnBrush was seamless. The designers they recommended for our products were exceptionally talented.",

      author: "Rajesh Patel",
      position: "Marketing Director",
      rating: 5,
      avatar: ASSETS.USER_2, // Replace with actual avatar image path
    },
    {
      quote:
        "PixelnBrush helped me elevate my brand's image with their innovative designs. Their professionalism and creativity exceeded my expectations.",

      author: "Priya Singh",
      position: "Product Manager",
      rating: 5,
      avatar: ASSETS.USER_1,  // Replace with actual avatar image path
    },
    {
      quote:
        "PixelnBrush's designers are wizards! They transformed my ideas into beautiful designs that captivated my customers. Thank you so much!",

      author: "Amit Khanna",
      position: "CEO, StartUp Co.",
      rating: 5,
      avatar: ASSETS.USER_3, // Replace with actual avatar image path
    },
  ],
  // Second Page of Testimonials
  [
    {
      quote:
        "The experience with PixelnBrush was fantastic! They understood my requirements perfectly and delivered outstanding results. Truly impressed!",
      author: "Sanjay Gupta",
      position: "Founder",
      rating: 4,
      avatar: ASSETS.USER_1, // Replace with actual avatar image path
    },
    {
      quote:
        "PixelnBrush made my dream project a reality. The designers were not only talented but also attentive to detail. My project turned out perfect!",
      author: "Kavita Sharma",
      position: "Creative Director",
      rating: 5,
      avatar: ASSETS.USER_1,  // Replace with actual avatar image path
    },
    {
      quote:
        "I was blown away by PixelnBrush's designers' creativity and professionalism. They truly have a knack for understanding client needs!",
      author: "Rahul Kumar",
      position: "Operations Head",
      rating: 4,
      avatar: ASSETS.USER_3, // Replace with actual avatar image path
    },
    {
      quote:
        "PixelnBrush helped me take my brand to the next level. The designers were responsive, talented, and a pleasure to work with. Highly recommend!",
      author: "Ananya Mehra",
      position: "Product Lead",
      rating: 5,
      avatar: ASSETS.USER_1,  // Replace with actual avatar image path
    },
  ],
  // Add more pages as needed
];

const TestimonialsPageView = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const [fade, setFade] = useState(false);

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < testimonials.length) {
      setFade(true);
      setTimeout(() => {
        setFade(false);
        setCurrentPage(newPage);
      }, 500); // This duration should match the CSS transition-duration
    }
  };
  return (
    <Segment secondary attached="top" style={{padding:"5em"}}>
      <div className="testimonials-section">
        <div className="title-large">What Clients Are Saying About Us</div>
        <p className="medium-text">
          We've served more than 200 clients globally in the last Years and
          retained 97% of them.
        </p>
        <div className={`testimonials-container ${fade ? "fade" : ""}`}>
          {testimonials[currentPage].map((testimonial, index) => (
            <div key={index} className="testimonial-tile">
              <div className="testimonial-content">
                <p>"{testimonial.quote}"</p>

                <div className="rating">
                  {[...Array(testimonial.rating)].map((_, i) => (
                    <span key={i}>&#9733;</span>
                  ))}
                </div>
              </div>
              <Divider/>
              <div
                className="author-info"
                style={{ textAlign: "left", width: "100%" }}
              >
                <img src={testimonial.avatar} alt={testimonial.author} />
                <div>
                  <strong>{testimonial.author}</strong>
                  <br />
                  <span>{testimonial.position}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination">
          {testimonials.map((_, index) => (
            <button
              key={index}
              className={`page-indicator ${
                index === currentPage ? "active" : ""
              }`}
              onClick={() => handlePageChange(index)}
            ></button>
          ))}
        </div>
      </div>
    </Segment>
  );
};

export default TestimonialsPageView;
