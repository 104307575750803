import React, { useState } from "react";
import { Form, Button, Message } from "semantic-ui-react";
import { AccentButton } from "../../Custom/CustomButtons";

const Page1 = ({ onNext, data, setData }) => {
  const [errors, setErrors] = useState({});
  const [companyType, setCompanyType] = useState(data["companyType"] ?? "");
  const [companyName, setCompanyName] = useState(data["companyName"] ?? "");
  const [contactPerson, setContactPerson] = useState(
    data["contactPerson"] ?? ""
  );
  const [email, setEmail] = useState(data["email"] ?? "");
  const [phone, setPhone] = useState(data["phone"] ?? "");
  const [website, setWebsite] = useState(data["website"] ?? "");

  const handleNext = () => {
    const errors = validatePage1();
    if (Object.keys(errors).length === 0) {
      setData({
        companyType: companyType,
        companyName: companyName,
        contactPerson: contactPerson,
        email: email,
        phone: phone,
        website: website,
      });
      onNext();
    } else {
      setErrors(errors);
    }
  };

  const validatePage1 = () => {
    const errors = {};
    if (!companyType) errors.companyType = "Company Type is required";
    if (!companyName) errors.companyName = "Company Name is required";
    if (!contactPerson) errors.contactPerson = "Contact Person is required";
    if (!email) errors.email = "Email is required";
    if (!phone) errors.phone = "Phone Number is required";
    if (!website) errors.website = "Company Website is required";
    return errors;
  };

  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Select
          label="Company Type"
          options={[
            { key: "brand", text: "Brand", value: "brand" },
            {
              key: "manufacturer",
              text: "Manufacturer",
              value: "manufacturer",
            },
          ]}
          placeholder="Select Company Type"
          value={companyType}
          onChange={(e, { value }) => setCompanyType(value)}
          error={errors.companyType ? true : false}
        />
        <Form.Input
          label="Company Name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          error={errors.companyName ? true : false}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          label="Contact Person"
          value={contactPerson}
          onChange={(e) => setContactPerson(e.target.value)}
          error={errors.contactPerson ? true : false}
        />
        <Form.Input
          label="Email Address"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={errors.email ? true : false}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          label="Phone Number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          error={errors.phone ? true : false}
        />
        <Form.Input
          label="Company Website"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
          error={errors.website ? true : false}
        />
      </Form.Group>
      <br />
      <div style={{ textAlign: "right" }}>
        <AccentButton text="Next" onClick={handleNext} />
      </div>
      {Object.keys(errors).length > 0 && (
        <Message error header="Validation Error" list={Object.values(errors)} />
      )}
    </Form>
  );
};

export default Page1;
