import React from "react";
import { Helmet } from "react-helmet";
import {

  Image,
  Grid,
  Container,
  Header,
  Button,
} from "semantic-ui-react";
import { ASSETS } from "../../Utils/Assets";
import "./AboutUsPage.css";
import PortableContactUs from "../ContactUs/PortableContactUs/PortableContactUs";
import { Link } from "react-router-dom";

export default function AboutUsPage() {
  return (
    <div style={{ display: "block", textAlign: "center" }}>
      <Helmet>
        <title>About - PixelnBrush</title>
        <meta
          name="description"
          content="PixelnBrush: Streamline your brand's creative scale-up by hiring top freelance design and creative experts. Discover a curated community of professional designers ready to elevate your projects. Whether you're a brand looking to hire or a designer seeking opportunities, register with us and experience seamless collaboration and growth."
        />
      </Helmet>
      <div
        className="about-background"
        style={{
          display: "table",
          position: "relative",
          width: "100%",
          padding: "10% 5%",
          textAlign: "center",
        }}
      >
        <div className="dark-tint" />
        <Header
          id="services-page-heading"
          as="h1"
          inverted
          style={{
            display: "table-cell",
            position: "relative",
            zIndex: "4",
            textAlign: "left",
            verticalAlign: "middle",
          }}
        >
          <Header.Content>
            <p className="heading-text">PixelnBrush</p>
          </Header.Content>
          <br />
         <Link to={"/contact"}>
         <Button  size="large" style={{ marginTop: "2%" }}>
            <Button.Content visible>
              Contact Us
            </Button.Content>
          </Button>
         </Link>
        </Header>
      </div>
      <div style={{ padding: "1em" }}>
        <Grid padded columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Image size="large" centered src={ASSETS.ABOUT_1} />
            </Grid.Column>
            <Grid.Column textAlign="left">
              <Header className="heading-text" as="h1">
                Welcome to PixelnBrush
              </Header>
              <Container textAlign="justified">
                <p className="medium-text">
                  Welcome to PixelnBrush, destination for unparalleled design
                  collaboration. At PixelnBrush, we believe in the power of
                  creativity to transform brands and manufacturing processes.
                  With a keen understanding of the gaps and pain areas faced by
                  brands and manufacturers in finding the right talent for their
                  design and creative needs, we've curated a platform that
                  bridges the divide between vision and execution.
                </p>
                <p className="medium-text">
                  In today’s world where design is paramount to success, where
                  design is at the core of success, PixelnBrush is a shining
                  example of inventiveness through collaboration. Our platform
                  serves as an active marketplace where ideas are given life as
                  brands interact with manufacturers and creative minds
                  converge. For design inputs, collection development
                  facilitation or acting as a domain for brands to either join
                  or hire designers from; this market place is your all-in-one
                  solution- it is called Pixelnbrush.
                </p>
                <p className="medium-text">
                  We understand the challenges faced by both brands and
                  designers. Brands struggle to find the perfect match for their
                  creative endeavors, often resulting in subpar outcomes or
                  missed opportunities. Similarly, talented designers often find
                  themselves trapped in a cycle of underutilization, unable to
                  showcase their skills to their fullest potential.
                </p>
              </Container>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column verticalAlign="middle">
              <Container textAlign="justified">
                <p className="medium-text">
                  PixelnBrush aims to change this narrative. By connecting the
                  community of designers and creative minds with brands and
                  manufacturers at global level, we ensure that every project is
                  handled by a vetted team of creative professionals. Our
                  platform is designed to foster collaboration, innovation, and
                  excellence in every aspect of design. Central to our ethos is
                  the recognition that successful branding transcends mere
                  aesthetics; it demands a profound comprehension of
                  geographical landscapes, market dynamics, consumer behaviors,
                  and cultural subtleties.
                </p>
                <p className="medium-text">
                  This is why we prioritize cross-border collaboration and
                  support international initiatives. Whether it involves
                  interpreting the latest fashion trends or tailoring designs to
                  suit specific geographic conditions, PixelnBrush help to adapt
                  at meeting the challenge head-on.
                </p>
                <p className="medium-text">
                  PixelnBrush, is not just a platform; rather a cultivating a
                  community of creativity. With a commitment to quality,
                  integrity, and collaboration, Pixelnbrush aims to
                  revolutionizing the way brands and manufacturers approach
                  design.
                </p>
              </Container>
            </Grid.Column>
            <Grid.Column>
              <Image centered src={ASSETS.ABOUT_4} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Image centered src={ASSETS.ABOUT_2} />
            </Grid.Column>
            <Grid.Column textAlign="left">
              <Header className="title-large">Mission</Header>
              <Container className="medium-text" textAlign="justified">
                At PixelnBrush, we envision a world where brands and creatives
                unite on a canvas of boundless possibilities. Beyond being a
                mere platform, we are the heartbeat where visions come alive,
                where the mastery of manufacturing intertwines with the vibrant
                creativity of global designers. Our mission is clear: to close
                the gap between brands, manufacturers, and creative talent.
                PixelnBrush isn't just a destination; it's a sanctuary for
                design collaboration, input sourcing, and the evolution of
                collections. Join us on this journey where every stroke, every
                idea, and every connection is infused with the fervor of
                artistic expression.
              </Container>
              <Header className="title-large">Vision</Header>
              <Container className="medium-text" textAlign="justified">
                At PixelnBrush, we envision a world where creativity knows no
                bounds, where brands and manufacturers seamlessly collaborate
                with global designers to bring visions to life. Our platform
                serves as a catalyst for innovation, transforming the landscape
                of design through unparalleled collaboration and ingenuity. We
                strive to empower creative minds, bridge gaps, and redefine the
                possibilities of design in every corner of the globe.
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <PortableContactUs />
    </div>
  );
}
// Importing the CSS file for styling
