import React, { useState } from "react";
import { Grid, Icon } from "semantic-ui-react";
import "./FormWithAnimation.css"; // Import CSS file for styling
import HireADesignerForm from "./HireADesignerForm";

const FormWithAnimation = () => {
  const [formData, setFormData] = useState({
    companyType: "",
    companyName: "",
    contactPerson: "",
    email: "",
    phone: "",
    website: "",
    companyDescription: "",
    projectDetails: "",
    budget: "",
    designPreferences: "",
  });

  const handleDataChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = () => {
    // Handle form submission
    console.log("Form submitted:", formData);
  };

  return (
    <div className="background" style={{ padding: "4rem" }}>
      <Grid stackable columns={2} textAlign="left">
        <Grid.Column className="animation-content">
          <div className="animation-wrapper">
            <h2>Welcome to Our Creative Form</h2>
            <p>
              Let's bring your ideas to life! Our platform connects you with
              talented creators ready to take on your project.
            </p>
            <Icon name="paint brush" size="huge" />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className="form-container" >
            <HireADesignerForm />
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default FormWithAnimation;
