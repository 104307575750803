import React from "react";
import { Grid, List } from "semantic-ui-react";
import ContactForm from "../MapAndForm/ContactForm";
import "./PortableContactUs.css"; // Make sure to import the CSS file for styling
import "./ContactConsultants.css"; // Ensure you have a CSS file with this name in the same directory
import { ASSETS } from "../../../Utils/Assets";

const PortableContactUs = () => {
  // Assuming you have a form submission handler
  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your form submission logic here
    alert("Form submitted!"); // Placeholder action
  };

  return (
    <div className="contact-us-section">
      <div className="contact-us-content">
        <Grid padded stackable>
          <Grid.Column width={6}>
            <ContactConsultants />
          </Grid.Column>
          <Grid.Column width={10}>
            <ContactForm secondary={false} />
          </Grid.Column>
        </Grid>
      </div>
    </div>
  );
};

export default PortableContactUs;

// Dummy images for account managers, replace these URLs with your actual image URLs
const managerImages = [
  ASSETS.USER_1, // Replace with the actual image path for Manager 1
  ASSETS.USER_2, // Replace with the actual image path for Manager 2
  ASSETS.USER_3, // Replace with the actual image path for Manager 3
];

const ContactConsultants = () => {
  return (
    <div className="consultants-container">
      <div className="consultants-header" style={{ textAlign: "left" }}>
        <h1>Talk To Our Consultants</h1>
        <p>Get Custom Solutions & Recommendations, Estimates.</p>
      </div>
      <List style={{ textAlign: "left" }} relaxed>
        <List.Item>
          <List.Icon name="check circle" size="large" color="black" />
          <List.Content>
            <List.Header>Anjali Sharma</List.Header>
            <List.Description>
              PixelnBrush connected me with top designers who brought my vision
              to life.
            </List.Description>
          </List.Content>
        </List.Item>
        <br />
        <List.Item>
          <List.Icon name="check circle" size="large" color="black" />
          <List.Content>
            <List.Header>Rajesh Patel</List.Header>
            <List.Description>
              Working with PixelnBrush was seamless. The designers they
              recommended for our products were exceptionally talented.
            </List.Description>
          </List.Content>
        </List.Item>
      </List>
      <br />
      <div className="manager-images" style={{ textAlign: "left" }}>
        {managerImages.map((imgSrc, index) => (
          <img key={index} src={imgSrc} alt={`Account Manager ${index + 1}`} />
        ))}
      </div>
    </div>
  );
};
