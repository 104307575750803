import React, { useState } from 'react';
import './FAQSection.css'; // Import CSS file for styling

// Custom graphic for FAQ section (Replace 'faqGraphic' with the path to your graphic)
// import faqGraphic from './faqGraphic.jpg';

const FAQSection = () => {
  // State to track active index of the expanded FAQ item
  const [activeIndex, setActiveIndex] = useState(null);

  // Array of FAQs with questions and answers
  const faqs = [
    {
      question: "What kind of projects will I work on?",
      answer: "From branding and web design to interactive media, our projects span a diverse range of industries and creative challenges. You'll have the opportunity to work on meaningful designs that push boundaries and create impact."
    },
    {
      question: "How does the mentorship program work?",
      answer: "Our mentorship program pairs you with experienced designers who provide guidance, feedback, and support. It's a personalized experience designed to accelerate your growth and integrate you into our community of creative professionals."
    },
    {
      question: "What support does the company provide for professional development?",
      answer: "We offer workshops, training sessions, and resources to help you enhance your skills and stay ahead of industry trends. Plus, you'll have access to conferences and professional development allowances to support your career trajectory."
    },
    {
      question: "Can I choose the projects I work on?",
      answer: "While project assignments consider business needs, we strive to align them with your interests and career goals. We believe in empowering our designers to work on projects that inspire them and contribute to their professional growth."
    }
  ];

  // Function to toggle the active FAQ item
  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faqSection">
      {/* FAQ section title and description */}
      <div className="faqHeader">
        <h2 className="faqTitle">Frequently Asked Questions</h2>
        <p className="faqDescription">Explore the answers to commonly asked questions about working with us and starting your design journey.</p>
      </div>

      {/* FAQ items */}
      <div className="faqList">
        {faqs.map((faq, index) => (
          <div key={index} className={`faqItem ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleFAQ(index)}>
            <div className="faqQuestion">{faq.question}</div>
            <div className="faqAnswer">{activeIndex === index && <p>{faq.answer}</p>}</div>
          </div>
        ))}
      </div>

      {/* FAQ section background graphic */}
      {/* <div className="faqImageContainer">
        <img src={faqGraphic} alt="FAQ Graphic" className="faqImage" />
      </div> */}
    </div>
  );
};

export default FAQSection;
