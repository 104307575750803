import React, { useState } from "react";
import { Form, Input, Button, Message, Icon, Segment, Grid } from "semantic-ui-react";
import { AccentButton } from "../../../Custom/CustomButtons";

const EducationEntry = ({
  entry,
  onUpdate,
  onRemove,
  index,
  validationErrors,
}) => {
  return (
    <Segment
      basic
      secondary
      attached="bottom"
      style={{ border: "1px solid lightgrey" }}
    >
      <Form.Group widths="equal">
        <Form.Field error={!!validationErrors[`title-${index}`]}>
          <Input
            placeholder="Title"
            value={entry.title}
            onChange={(e) =>
              onUpdate(index, { ...entry, title: e.target.value })
            }
          />
          {/* {validationErrors[`title-${index}`] && (
            <Message color="red">{validationErrors[`title-${index}`]}</Message>
          )} */}
        </Form.Field>
        <Form.Field error={!!validationErrors[`year-${index}`]}>
          <Input
            placeholder="Year of Graduation"
            value={entry.year}
            onChange={(e) =>
              onUpdate(index, { ...entry, year: e.target.value })
            }
          />
          {/* {validationErrors[`year-${index}`] && (
            <Message color="red">{validationErrors[`year-${index}`]}</Message>
          )} */}
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field
          error={!!validationErrors[`institution-${index}`]}
          width={16}
        >
          <Input
            placeholder="Institution Name"
            value={entry.institution}
            onChange={(e) =>
              onUpdate(index, { ...entry, institution: e.target.value })
            }
          />
          {/* {validationErrors[`institution-${index}`] && (
            <Message color="red" compact>
              {validationErrors[`institution-${index}`]}
            </Message>
          )} */}
        </Form.Field>
      </Form.Group>
      <Button
        compact
        floated="right"
        type="button"
        icon="trash"
        color="red"
        basic
        content="Remove"
        circular
        onClick={() => onRemove(index)}
      />
    </Segment>
  );
};

export const EducationEntriesSection = ({
  onNext,
  onPrevious,
  initialData,
}) => {
  const [educationEntries, setEducationEntries] = useState(
    initialData || [{ title: "", year: "", institution: "" }]
  );
  const [validationErrors, setValidationErrors] = useState({});

  const handleAddEducationEntry = () => {
    if (educationEntries.length == 2) {
      return;
    }
    setEducationEntries([
      ...educationEntries,
      { title: "", year: "", institution: "" },
    ]);
  };

  const handleRemoveEducationEntry = (index) => {
    const newEntries = [...educationEntries];
    newEntries.splice(index, 1);
    setEducationEntries(newEntries);
  };

  const handleUpdateEducationEntry = (index, updatedEntry) => {
    const newEntries = [...educationEntries];
    newEntries[index] = updatedEntry;
    setEducationEntries(newEntries);
  };

  const validate = () => {
    const newErrors = {};
    educationEntries.forEach((entry, index) => {
      if (!entry.title.trim())
        newErrors[`title-${index}`] = "Title is required";
      if (!entry.year.match(/^[0-9]{4}$/))
        newErrors[`year-${index}`] = "Year must be 4 digits";
      if (!entry.institution.trim())
        newErrors[`institution-${index}`] = "Institution name is required";
    });
    setValidationErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      onNext(educationEntries);
    }
  };

  return (
    <Form>
      <Grid>
        <Grid.Column width={2} verticalAlign="middle" textAlign="left">
          <Button
            icon={<Icon name="arrow circle left" />}
            onClick={onPrevious}
          />
        </Grid.Column>
        <Grid.Column width={12}>
          <div className="title-medium">Education <span className="medium-text">(Optional)</span></div>
          <div className="medium-text">
            Enter your Education Details
          </div>
        </Grid.Column>
        <Grid.Column width={2} verticalAlign="middle" textAlign="right">
          <Button
            icon={<Icon name="arrow circle right" />}
            onClick={handleSubmit}
          />
        </Grid.Column>
      </Grid>
      <br />
      {educationEntries.map((entry, index) => (
        <EducationEntry
          key={index}
          entry={entry}
          onUpdate={handleUpdateEducationEntry}
          onRemove={handleRemoveEducationEntry}
          index={index}
          validationErrors={validationErrors}
        />
      ))}
      <Button
        type="button"
        onClick={handleAddEducationEntry}
        icon
        labelPosition="left"
        color="green"
        disabled={educationEntries.length >= 2}
      >
        <Icon name="add" />
        Add Education Entry
      </Button>
    </Form>
  );
};
