import React, { useState } from "react";
import { Container, Header, Icon, Message, Segment } from "semantic-ui-react";
import { BasicInfoSection } from "./Sections/Form/BasicInfoSection";
import { EducationEntriesSection } from "./Sections/Form/EducationEntriesSection";
import { ProjectEntriesSection } from "./Sections/Form/ProjectEntriesSection";
import { SkillEntriesSection } from "./Sections/Form/SkillEntriesSection";
import { ExperienceEntriesSection } from "./Sections/Form/ExperienceEntriesSection";
import axios from "axios";
import { AUTH_REGISTER_AS_DESIGNER } from "../../Utils/URLConstants";

export const RegisterAsDesignerForm = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [error, setError] = useState();
  const [formData, setFormData] = useState({
    personalInfo: {},
    education: [],
    projects: [],
    skills: [],
    experiences: [],
  });

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  const sendMessage = async (data) => {
    setLoading(true);
    setError(null);
    try {
      setRequestSent(false);
      await axios
        .post(AUTH_REGISTER_AS_DESIGNER, {
          data: data,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 0) {
            setRequestSent(true);
          } else {
            setError(res.data.message);
          }
        })
        .catch((e) => {
          setRequestSent(false);
          setError("There was an Signing Up!");
        });
    } catch (e) {
      setRequestSent(false);
      setError("There was an Signing Up!");
    }

    setLoading(false);
  };

  const handleSubmission = (data) => {
    switch (currentPage) {
      case 1:
        setFormData({ ...formData, personalInfo: data });
        break;
      case 2:
        setFormData({ ...formData, education: data });
        break;
      case 3:
        setFormData({ ...formData, projects: data });
        break;
      case 4:
        setFormData({ ...formData, skills: data });
        break;
      case 5:
        setFormData({ ...formData, experiences: data });
        break;
      default:
        break;
    }
    if (currentPage < 5) {
      console.log(formData);
      nextPage();
    } else {
      sendMessage(formData);
    }
  };

  const renderPage = () => {
    switch (currentPage) {
      case 1:
        return (
          <BasicInfoSection
            onNext={(data) => handleSubmission(data)}
            data={formData.personalInfo}
          />
        );
      case 2:
        return (
          <EducationEntriesSection
            onNext={(data) => handleSubmission(data)}
            onPrevious={prevPage}
            initialData={formData.education}
          />
        );
      case 3:
        return (
          <ProjectEntriesSection
            onNext={(data) => handleSubmission(data)}
            onPrevious={prevPage}
            initialData={formData.projects}
          />
        );
      case 4:
        return (
          <SkillEntriesSection
            onNext={(data) => handleSubmission(data)}
            onPrevious={prevPage}
            initialData={formData.skills}
          />
        );
      case 5:
        return (
          <ExperienceEntriesSection
            onSubmit={(data) => handleSubmission(data)}
            onPrevious={prevPage}
            initialData={formData.experiences}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Segment loading={loading} basic>
      {requestSent ? (
        <Container style={{ minHeight: "50vh",textAlign: "center" }}>
          <Icon name="check circle outline" size="massive" />
          <Header as="h2" textAlign="center">
            You have successfully registered on PixelnBrush
          </Header>
          <Message info>
            Thank you for Registration on PixelnBrush. We have sent you
            credentials via Email (Please check your Spam folder if the email is
            not received in Primary Folder). Make sure to keep them private
          </Message>
        </Container>
      ) : (
        <div>{renderPage()}</div>
      )}
      {error && <Message error content={<div>{error}</div>} />}
    </Segment>
  );
};
