import React, { useState } from "react";
import {
  Step,
  Icon,
  Button,
  Container,
  Divider,
  Segment,
  Message,
  Header,
  Grid,
} from "semantic-ui-react";
import Page1 from "./FormSections/Page1";
import Page2 from "./FormSections/Page2";
import Page3 from "./FormSections/Page3";
import "./FormWithAnimation.css";
import { AUTH_REGISTER_AS_BRAND } from "../../Utils/URLConstants";
import axios from "axios";

const HireADesignerForm = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [error, setError] = useState();
  const [formData, setFormData] = useState({
    companyType: "",
    companyName: "",
    contactPerson: "",
    email: "",
    phone: "",
    website: "",
    companyDescription: "",
    projectDetails: "",
    budget: "",
    designPreferences: "",
  });

  const sendMessage = async (data) => {
    setLoading(true);
    setError(null);
    try {
      setRequestSent(false);
      await axios
        .post(AUTH_REGISTER_AS_BRAND, {
          data: data,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 0) {
            setRequestSent(true);
          } else {
            setError(res.data.message);
          }
        })
        .catch((e) => {
          setRequestSent(false);
          setError("There was an Signing Up!");
        });
    } catch (e) {
      setRequestSent(false);
      setError("There was an Signing Up!");
    }

    setLoading(false);
  };

  const onNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const onPrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const onSubmit = (newData) => {
    setFormData({ ...formData, ...newData });
    sendMessage(formData);
  };

  const handleDataChange = (newData) => {
    setFormData({ ...formData, ...newData });
  };

  const steps = [
    { title: "Company Information", icon: "building" },
    { title: "Company Description", icon: "info circle" },
    { title: "Project Details", icon: "file alternate outline" },
  ];

  return (
    <Segment loading={loading} basic>
      {requestSent ? (
        <Container style={{ minHeight: "50vh", textAlign: "center" }}>
          <Icon name="check circle outline" size="massive" />
          <Header as="h3" textAlign="center">
            You have successfully registered on PixelnBrush
          </Header>
          <Message info>
            Thank you for Registration on PixelnBrush.
            <br />
            <br />
            We have sent you credentials via Email. Please check your Spam
            folder if the email is not received in Primary Folder. Make sure to
            keep them private
          </Message>
        </Container>
      ) : (
        <Container style={{ minHeight: "50vh" }}>
          <Step.Group fluid size="mini">
            {steps.map((step, index) => (
              <Step
                key={index}
                active={index + 1 === currentPage}
                completed={index + 1 < currentPage}
              >
                <Icon name={step.icon} />
                <Step.Content>
                  <Step.Title>{step.title}</Step.Title>
                </Step.Content>
              </Step>
            ))}
          </Step.Group>
          <Divider />
          {currentPage === 1 && (
            <Page1 onNext={onNext} data={formData} setData={handleDataChange} />
          )}
          {currentPage === 2 && (
            <Page2
              onNext={onNext}
              onPrev={onPrev}
              data={formData}
              setData={handleDataChange}
            />
          )}
          {currentPage === 3 && (
            <Page3 onPrev={onPrev} onSubmit={onSubmit} data={formData} />
          )}
        </Container>
      )}
      {error && <Message error content={<div>{error}</div>} />}
    </Segment>
  );
};

export default HireADesignerForm;
