import React, { Component } from "react";
import { RegisterAsDesignerForm } from "./RegisterAsDesignerForm";
import { Button, Form, Grid, Header, List, Segment } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "./BecomeADesigner.css"; // Importing our custom CSS
import WhyChooseUs from "./Sections/WhyChooseUs";
import SuccessStories from "./Sections/SuccessStories";
import FAQSection from "./Sections/FAQSection";
import PortableContactUs from "../ContactUs/PortableContactUs/PortableContactUs";

const BecomeADesigner = () => {
  return (
    <div>
      <div className="becomeADesignerContainer">
        <Grid
          container
          stackable
          verticalAlign="middle"
          className="designerGrid"
        >
          <Grid.Row>
            <Grid.Column width={8} className="leftColumn">
              <Header
                inverted
                size="huge"
                className="designerHeader heading-text"
              >
                Join Our Creative Force
              </Header>
              <p className="designerText">
                Transform brands and shape futures as part of our design
                community. Dive into projects that inspire, challenge, and
                empower.
              </p>
              <List relaxed size="medium" inverted style={{ color: "white" }}>
                <List.Item>
                  <List.Icon name="check circle" inverted />
                  <List.Content>Innovative Projects</List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="check circle" />
                  <List.Content>Creative Freedom</List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="check circle" />
                  <List.Content>Community & Support</List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="check circle" />
                  <List.Content>Competative Compensation</List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={8}>
              <Segment
                className="designerFormSegment"
                style={{ minHeight: "70vh" }}
              >
                <RegisterAsDesignerForm />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <WhyChooseUs />
      <SuccessStories />
      <FAQSection />
      <PortableContactUs/>
    </div>
  );
};

export default BecomeADesigner;
