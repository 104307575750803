import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/storage";
import "firebase/firestore";

import { PROJECT_MODE, STAGING, TESTING } from "../Utils/Controller";

const PRODUCTION_CONFIG = {
  apiKey: "AIzaSyCYaAhr3LyEUF0DH9QmeqohOxD4ang9kwM",
  authDomain: "designhq-sourcenet.firebaseapp.com",
  projectId: "designhq-sourcenet",
  storageBucket: "designhq-sourcenet.appspot.com",
  messagingSenderId: "334241720114",
  appId: "1:334241720114:web:0b560c76f944b3b51e592b",
  measurementId: "G-LZGHCYD3G9",
};

var TEST_CONFIG = {
  apiKey: "AIzaSyAzIaRD-Saa1rJbvGGKwrJKltIhkV4YoiU",
  authDomain: "designhq-sourcenet-test.firebaseapp.com",
  projectId: "designhq-sourcenet-test",
  storageBucket: "designhq-sourcenet-test.appspot.com",
  messagingSenderId: "461684371432",
  appId: "1:461684371432:web:05c3e62d21ab66a5e77e5d",
  measurementId: "G-SGRYS04ZPN",
};

// Initialize Firebase
firebase.initializeApp(
  PROJECT_MODE === TESTING || PROJECT_MODE === STAGING
    ? TEST_CONFIG
    : PRODUCTION_CONFIG
);
firebase.analytics();
firebase.firestore();

// export const auth = firebaseAuth;
export default firebase;
