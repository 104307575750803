import React, { useState } from "react";
import { Form, Input, Button, TextArea, Message } from "semantic-ui-react";
import { AccentButton } from "../../../Custom/CustomButtons";

export const BasicInfoSection = ({ onNext,data }) => {
  const [fullName, setFullName] = useState(data['fullName']??"");
  const [profession, setProfession] = useState(data['profession']??"");
  const [email, setEmail] = useState(data['email']??"");
  const [contactNumber, setContactNumber] = useState(data['contactNumber']??"");
  const [bio, setBio] = useState(data['bio']??"");
  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!fullName.trim()) newErrors.fullName = "Full Name is required";
    if (!profession.trim()) newErrors.profession = "Profession is required";
    if (!email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/))
      newErrors.email = "Invalid email format";
    if (!contactNumber.match(/^\d{10}$/))
      newErrors.contactNumber = "Invalid contact number, must be 10 digits";
    if (!bio.trim()) newErrors.bio = "Bio is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      onNext({
        fullName,
        profession,
        email,
        contactNumber,
        bio,
      });
    }
  };

  return (
    <Form style={{ padding: 20 }}>
      <Form.Field required error={!!errors.fullName}>
        <label>Full Name</label>
        <Input
          placeholder="Full Name"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />
        {/* {errors.fullName && (
          <Message size="mini" color="red">
            {errors.fullName}
          </Message>
        )} */}
      </Form.Field>
      <Form.Field required error={!!errors.profession}>
        <label>Profession</label>
        <Input
          placeholder="Profession"
          value={profession}
          onChange={(e) => setProfession(e.target.value)}
        />
        {/* {errors.profession && (
          <Message size="mini" color="red">
            {errors.profession}
          </Message>
        )} */}
      </Form.Field>
      <Form.Field required error={!!errors.email}>
        <label>Email ID</label>
        <Input
          type="email"
          placeholder="Email ID"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {/* {errors.email && (
          <Message size="mini" color="red">
            {errors.email}
          </Message>
        )} */}
      </Form.Field>
      <Form.Field required error={!!errors.contactNumber}>
        <label>Contact Number</label>
        <Input
          placeholder="Contact Number"
          value={contactNumber}
          onChange={(e) => setContactNumber(e.target.value)}
        />
        {/* {errors.contactNumber && (
          <Message size="mini" color="red">
            {errors.contactNumber}
          </Message>
        )} */}
      </Form.Field>
      <Form.Field required error={!!errors.bio}>
        <label>Bio</label>
        <TextArea
          placeholder="Bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />
        {/* {errors.bio && (
          <Message size="mini" color="red">
            {errors.bio}
          </Message>
        )} */}
      </Form.Field>
      <div style={{textAlign:"right"}}>
      <AccentButton text={"Next"} onClick={handleSubmit} />
      </div>
    </Form>
  );
};
