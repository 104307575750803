import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Message,
  TextArea,
  Icon,
  Segment,
  Header,
  Grid,
} from "semantic-ui-react";
import { AccentButton } from "../../../Custom/CustomButtons";

const ProjectEntry = ({
  entry,
  onUpdate,
  onRemove,
  index,
  validationErrors,
}) => {
  return (
    <Segment
      basic
      secondary
      attached="bottom"
      style={{ border: "1px solid lightgrey" }}
    >
      <Form.Group widths="equal">
        <Form.Field error={!!validationErrors[`title-${index}`]}>
          <Input
            placeholder="Title of Project"
            value={entry.title}
            onChange={(e) =>
              onUpdate(index, { ...entry, title: e.target.value })
            }
          />
          {/* {validationErrors[`title-${index}`] && (
            <Message color="red">{validationErrors[`title-${index}`]}</Message>
          )} */}
        </Form.Field>
        <Form.Field error={!!validationErrors[`year-${index}`]}>
          <Input
            placeholder="Year of Project"
            value={entry.year}
            onChange={(e) =>
              onUpdate(index, { ...entry, year: e.target.value })
            }
          />
          {/* {validationErrors[`year-${index}`] && (
            <Message color="red">{validationErrors[`year-${index}`]}</Message>
          )} */}
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field
          width={"16"}
          error={!!validationErrors[`description-${index}`]}
        >
          <TextArea
            placeholder="Brief Description"
            value={entry.description}
            onChange={(e) =>
              onUpdate(index, { ...entry, description: e.target.value })
            }
          />
          {/* {validationErrors[`description-${index}`] && (
            <Message color="red">
              {validationErrors[`description-${index}`]}
            </Message>
          )} */}
        </Form.Field>
      </Form.Group>
      <Button
        type="button"
        icon="trash"
        color="red"
        content="Remove"
        basic
        floated="right"
        circular
        compact
        onClick={() => onRemove(index)}
      />
    </Segment>
  );
};

export const ProjectEntriesSection = ({ onNext, onPrevious, initialData }) => {
  const [projectEntries, setProjectEntries] = useState(
    initialData || [{ title: "", year: "", description: "" }]
  );
  const [validationErrors, setValidationErrors] = useState({});

  const handleAddProjectEntry = () => {
    setProjectEntries([
      ...projectEntries,
      { title: "", year: "", description: "" },
    ]);
  };

  const handleRemoveProjectEntry = (index) => {
    const newEntries = [...projectEntries];
    newEntries.splice(index, 1);
    setProjectEntries(newEntries);
  };

  const handleUpdateProjectEntry = (index, updatedEntry) => {
    const newEntries = [...projectEntries];
    newEntries[index] = updatedEntry;
    setProjectEntries(newEntries);
  };

  const validate = () => {
    const newErrors = {};
    projectEntries.forEach((entry, index) => {
      if (!entry.title.trim())
        newErrors[`title-${index}`] = "Title is required";
      if (!entry.year.match(/^[0-9]{4}$/))
        newErrors[`year-${index}`] = "Year must be 4 digits";
      if (!entry.description.trim())
        newErrors[`description-${index}`] = "Description is required";
    });
    setValidationErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      onNext(projectEntries);
    }
  };

  return (
    <div>
      <Grid>
        <Grid.Column width={2} verticalAlign="middle" textAlign="left">
          <Button
            icon={<Icon name="arrow circle left" />}
            onClick={onPrevious}
          />
        </Grid.Column>
        <Grid.Column width={12}>
          <div className="title-medium">Projects <span className="medium-text">(Optional)</span></div>
          <div className="medium-text">
            Add Projects you have created for your Resume
          </div>
        </Grid.Column>
        <Grid.Column width={2} verticalAlign="middle" textAlign="right">
          <Button
            icon={<Icon name="arrow circle right" />}
            onClick={handleSubmit}
          />
        </Grid.Column>
      </Grid>
      <br/>
      <Form>
        {projectEntries.map((entry, index) => (
          <ProjectEntry
            key={index}
            entry={entry}
            onUpdate={handleUpdateProjectEntry}
            onRemove={handleRemoveProjectEntry}
            index={index}
            validationErrors={validationErrors}
          />
        ))}
        <Button
          type="button"
          onClick={handleAddProjectEntry}
          icon
          labelPosition="left"
          color="green"
        >
          <Icon name="add" />
          Add Project
        </Button>
      </Form>
    </div>
  );
};
