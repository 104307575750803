import React from 'react';
import './SuccessStories.css'; // Update with new styles
import { Segment } from 'semantic-ui-react';
import { ASSETS } from '../../../Utils/Assets';

const stories = [
    {
        name: "Rajesh Kumar",
        role: "UI/UX Designer",
        quote: "Joining the team as a junior designer, I was mentored into a role where I now lead projects that redefine user experience for millions. This journey has been about growth, learning, and creating impactful designs.",
        image: ASSETS.DESIGNER_1, // Replace with actual image path
      },
  {
    name: "Priya Mehta",
    role: "UI/UX Designer",
    quote: "Joining the team as a junior designer, I was mentored into a role where I now lead projects that redefine user experience for millions. This journey has been about growth, learning, and creating impactful designs.",
    image: ASSETS.DESIGNER_2, // Replace with actual image path
  },
  
  {
    name: "Hritik Desai",
    role: "UI/UX Designer",
    quote: "Joining the team as a junior designer, I was mentored into a role where I now lead projects that redefine user experience for millions. This journey has been about growth, learning, and creating impactful designs.",
    image: ASSETS.DESIGNER_3, // Replace with actual image path
  },
  // Add other stories similarly
];

const SuccessStories = () => {
  return (
    <Segment padded secondary className="successStoriesContainer" style={{padding:"4rem"}}>
      <h2 className="successStoriesTitle">Our Designers' Journey</h2>
      <p className="successStoriesDescription">
        Explore the inspiring journeys of our designers who have carved their paths to success. From ambitious beginnings to groundbreaking achievements, these stories celebrate the spirit of creativity and determination.
      </p>
      <div className="storiesGrid">
        {stories.map((story, index) => (
          <div key={index} className="storyCard">
            <div className="storyImage" style={{ backgroundImage: `url(${story.image})` }}></div>
            <div className="storyContent">
              <h3 className="storyName">{story.name}</h3>
              <p className="storyRole">{story.role}</p>
              <p className="storyQuote">“{story.quote}”</p>
            </div>
          </div>
        ))}
      </div>
    </Segment>
  );
};

export default SuccessStories;
