import React, { Component } from "react";
import { Grid, Segment, Header, Button } from "semantic-ui-react";
import ContactForm from "./MapAndForm/ContactForm";
import { Helmet } from "react-helmet";
import {
  BRAND_NAME,
  SUPPORT_CONTACT,
  SUPPORT_CONTACT_TEXT,
  SUPPORT_EMAIL,
} from "../../Utils/Constants";

class ContactUs extends Component {
  render() {
    return (
      <div style={{ display: "block", textAlign: "center" }}>
        <Helmet>
          <title>Contact Us - PixelnBrush</title>
          <meta
            name="description"
            content={`Contact PixelnBrush 24/7 via Call on ${SUPPORT_CONTACT_TEXT} or Email at ${SUPPORT_EMAIL}`}
          />
        </Helmet>
        <div
          className="contact-background"
          style={{
            display: "table",
            position: "relative",
            width: "100%",
            padding: "10% 5%",
            textAlign: "center",
          }}
        >
          <div className="dark-tint" />
          <Header
            id="services-page-heading"
            as="h1"
            inverted
            style={{
              display: "table-cell",
              position: "relative",
              zIndex: "4",
              textAlign: "left",
              verticalAlign: "middle",
            }}
          >
            <Header.Content>
              <p className="heading-text">{BRAND_NAME}</p>
            </Header.Content>
            <br />
            <Button color="pink" size="large" style={{ marginTop: "2%" }}>
              <a href={`mailto:${SUPPORT_EMAIL}`} style={{ color: "white" }}>
                <Button.Content visible>
                  For Business Enquiry Click Here
                </Button.Content>
              </a>
            </Button>
          </Header>
        </div>

        <Segment
          secondary
          basic
          textAlign="left"
          style={{ margin: "0px 0px", padding: "5% 2%" }}
        >
          <Grid divided stackable>
            <Grid.Row divided>
              <Grid.Column width="10">
                <ContactForm />
              </Grid.Column>
              <Grid.Column verticalAlign="middle" width="6">
                <Header color="red">
                  <p className="large-text" style={{ fontWeight: "bold" }}>
                    {" "}
                    Contact Information{" "}
                  </p>
                </Header>

                <p className="medium-text">
                  <span className="title-small">Email: </span>
                  <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> <br />
                </p>
                <p>
                  <span className="title-small">Call: </span>{" "}
                  <a href={`tel:${SUPPORT_CONTACT}`}>{SUPPORT_CONTACT_TEXT}</a>
                </p>
                <br />
                <div className="title-medium">Feedback and Suggestions:</div>
                <div className="medium-text">
                  Your feedback is invaluable to us as we continuously work to
                  enhance our services. If you have any suggestions, comments,
                  or ideas on how we can better serve you, please don't hesitate
                  to let us know through our online feedback form. We're
                  committed to providing the best experience possible and your
                  input helps us achieve that.
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <div style={{ height: "50vh", width: "100%" }}>
          <iframe
            title="Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3549.4005540576463!2d78.03956727544868!3d27.17514477649168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39747121d702ff6d%3A0xdd2ae4803f767dde!2sTaj%20Mahal!5e0!3m2!1sen!2sin!4v1709543097587!5m2!1sen!2sin"
            frameBorder="0"
            style={{ border: "none", width: "100%", height: "100%" }}
            allowFullScreen=""
          ></iframe>
        </div>
      </div>
    );
  }
}


export default ContactUs;
