import React, { useState } from "react";
import { Form, Input, Button, Message, Icon, Grid } from "semantic-ui-react";

const SkillEntry = ({ skill, onUpdate, onRemove, index, validationError }) => {
  return (
    <Form.Group>
      <Form.Field error={!!validationError} width={14}>
        <Input
          placeholder="Skill"
          value={skill}
          onChange={(e) => onUpdate(index, e.target.value)}
        />
        {/* {validationError && <Message color="red">{validationError}</Message>} */}
      </Form.Field>
      <Form.Field  width={2}>
        <Button
          type="button"
          icon="trash"
          color="red"
          onClick={() => onRemove(index)}
        />
      </Form.Field>
    </Form.Group>
  );
};

export const SkillEntriesSection = ({ onNext, onPrevious, initialData }) => {
  const [skills, setSkills] = useState(initialData || [""]);
  const [validationErrors, setValidationErrors] = useState([]);

  const handleAddSkill = () => {
    setSkills([...skills, ""]);
  };

  const handleRemoveSkill = (index) => {
    const newSkills = [...skills];
    newSkills.splice(index, 1);
    setSkills(newSkills);
    const newValidationErrors = [...validationErrors];
    newValidationErrors.splice(index, 1);
    setValidationErrors(newValidationErrors);
  };

  const handleUpdateSkill = (index, updatedSkill) => {
    const newSkills = [...skills];
    newSkills[index] = updatedSkill;
    setSkills(newSkills);
  };

  const validate = () => {
    const newErrors = skills.map((skill) =>
      skill.trim() ? "" : "Skill cannot be empty"
    );
    setValidationErrors(newErrors);
    return !newErrors.some((error) => error);
  };

  const handleSubmit = () => {
    if (validate()) {
      onNext(skills);
    }
  };

  return (
    <Form>
      <Grid>
        <Grid.Column width={2} verticalAlign="middle" textAlign="left">
          <Button
            icon={<Icon name="arrow circle left" />}
            onClick={onPrevious}
          />
        </Grid.Column>
        <Grid.Column width={12}>
          <div className="title-medium">
            Skills <span className="medium-text">(Optional)</span>
          </div>
          <div className="medium-text">Add your Skills</div>
        </Grid.Column>
        <Grid.Column width={2} verticalAlign="middle" textAlign="right">
          <Button
            icon={<Icon name="arrow circle right" />}
            onClick={handleSubmit}
          />
        </Grid.Column>
      </Grid>
      <br />
      {skills.map((skill, index) => (
        <SkillEntry
          key={index}
          skill={skill}
          onUpdate={handleUpdateSkill}
          onRemove={handleRemoveSkill}
          index={index}
          validationError={validationErrors[index]}
        />
      ))}
      <Button
        type="button"
        onClick={handleAddSkill}
        icon
        labelPosition="left"
        color="green"
      >
        <Icon name="add" />
        Add Skill
      </Button>
    </Form>
  );
};
