import React, { Component } from "react";
import { Grid, Segment, Header, Menu, Icon } from "semantic-ui-react";
import { openModal } from "../../Redux/Modals/ModalActions";
import { connect } from "react-redux";
import { signOut } from "../../Redux/Auth/authActions";
import withRouter from "../../Utils/router/router_hocs";
import { AccentButton } from "../Custom/CustomButtons";
import { MODAL_LOGIN_REGISTER } from "../Modals/Auth/LoginRegisterModal";

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const actions = {
  openModal,
  signOut,
};

class UserProfilePage extends Component {
  constructor(props) {
    super(props);
    this.profileLeftBarHeight = 0;
    this.contentSectionWidth = 0;
    this.currentSectionId = "";
  }

  state = {
    activeMenuItem: "about",
  };

  componentDidMount() {
    let param = this.props.params.id;
    this.currentSectionId = param;
    this.setState({ activeMenuItem: param });
  }

  loadInitialData = async () => {
    await this.props.getAddressList();
  };

  componentDidUpdate() {
    let previousSectionID = this.currentSectionId;
    if (this.props.profile == null) {
      this.props.navigate("/");
    }
    this.currentSectionId = this.props.params.id;
    if (previousSectionID !== this.currentSectionId) {
      this.setState({
        activeMenuItem: this.currentSectionId,
      });
    }
  }

  handleItemClick = (e, { name }) => this.setState({ activeMenuItem: name });

  getRightComponent = (props) => {
    return <div></div>
  };

  render() {
    const { auth, signOut, profile, openModal } = this.props;
    const authenticated = auth.isLoaded && !auth.isEmpty;
    const { isLoaded } = auth;
    const { activeMenuItem } = this.state;
    return authenticated ? (
      <div>
        {profile && (
          <Segment
            basic
            loading={!isLoaded}
            style={{ marginTop: "0px", padding: "0px" }}
          >
            <Grid stackable>
              <Grid.Column width="4" only="computer" stretched>
                <Menu
                  inverted
                  fluid
                  vertical
                  className="profile-menu"
                  style={{
                    boxShadow: "none",
                    border: "none",
                    borderRadius: "0px",
                  }}
                >
                  <Menu.Item>
                    <Header textAlign="left" inverted>
                      Welcome,
                      <Header.Subheader style={{ marginTop: "1%" }}>
                        {profile.firstName + " " + profile.lastName ?? "Guest"} ({profile.phoneNumber})
                      </Header.Subheader>
                    </Header>
                  </Menu.Item>
                  <Menu.Item
                    style={{ textAlign: "left" }}
                    name="help"
                    active={activeMenuItem === "help"}
                    onClick={this.handleItemClick}
                  >
                    <Icon name="chat" />
                    Support Centre
                  </Menu.Item>
                  <Menu.Item
                    style={{ textAlign: "left" }}
                    onClick={() => {
                      let confirmation = window.confirm(
                        "Do you really wish to Log Out?"
                      );
                      if (confirmation) {
                        signOut();
                      }
                    }}
                  >
                    <Icon name="log out" />
                    Sign Out
                  </Menu.Item>
                </Menu>
              </Grid.Column>
              <Grid.Column only="mobile" width="16">
                <Menu
                  inverted
                  fluid
                  widths="2"
                  className="profile-menu"
                  // style={{ boxShadow: "none", border: "none" }}
                >
                  <Menu.Item
                    name="orders"
                    active={activeMenuItem === "orders"}
                    onClick={this.handleItemClick}
                  >
                    My Bookings
                  </Menu.Item>
                  <Menu.Item
                    name="help"
                    active={activeMenuItem === "help"}
                    onClick={this.handleItemClick}
                  >
                    Support Centre
                  </Menu.Item>
                </Menu>
              </Grid.Column>
              <Grid.Column
                computer="12"
                mobile="16"
                tablet="16"
                style={{ paddingLeft: "0px" }}
              >
                <div style={{ width: "100%",position:"relative" }}>
                  Right Component
                </div>
              </Grid.Column>
            </Grid>
          </Segment>
        )}
      </div>
    ) : (
      <Grid columns={1} centered>
        <Grid.Column verticalAlign="middle">
          <Segment placeholder style={{ height: "70vh" }}>
            <Header>
              <Header.Content>Please Login to Continue</Header.Content>
            </Header>
            <AccentButton
              labelPosition="left"
              fluid
              onClick={() => {
                openModal(MODAL_LOGIN_REGISTER, { forLogin: true });
              }}
              text="Log In / Sign Up"
              style={{ padding: "2rem 0rem" }}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

export default withRouter(connect(mapStateToProps, actions)(UserProfilePage));
