import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Message,
  TextArea,
  Icon,
  Grid,
  Segment,
} from "semantic-ui-react";
import { PRIMARY_COLOR } from "../../../../Utils/Constants";

const ExperienceEntry = ({
  experience,
  onUpdate,
  onRemove,
  index,
  validationErrors,
}) => {
  return (
    <Segment
      basic
      secondary
      attached="bottom"
      style={{ border: "1px solid lightgrey" }}
    >
      <Form.Group widths={"equal"}>
        <Form.Field error={!!validationErrors[`from-${index}`]}>
          <label>From</label>
          <Input
            type="date"
            value={experience.from}
            onChange={(e) =>
              onUpdate(index, { ...experience, from: e.target.value })
            }
          />
          {/* {validationErrors[`from-${index}`] && (
            <Message color="red">{validationErrors[`from-${index}`]}</Message>
          )} */}
        </Form.Field>
        <Form.Field error={!!validationErrors[`to-${index}`]}>
          <label>To</label>
          <Input
            type="date"
            value={experience.to}
            onChange={(e) =>
              onUpdate(index, { ...experience, to: e.target.value })
            }
          />
          {/* {validationErrors[`to-${index}`] && (
            <Message color="red">{validationErrors[`to-${index}`]}</Message>
          )} */}
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field
          width={16}
          error={!!validationErrors[`description-${index}`]}
        >
          <label>Description</label>
          <TextArea
            placeholder="Describe the experience"
            value={experience.description}
            onChange={(e) =>
              onUpdate(index, { ...experience, description: e.target.value })
            }
          />
          {/* {validationErrors[`description-${index}`] && (
            <Message color="red">
              {validationErrors[`description-${index}`]}
            </Message>
          )} */}
        </Form.Field>
      </Form.Group>
      <Button
        compact
        floated="right"
        type="button"
        icon="trash"
        color="red"
        basic
        content="Remove"
        circular
        onClick={() => onRemove(index)}
      />
    </Segment>
  );
};

export const ExperienceEntriesSection = ({
  onSubmit,
  onPrevious,
  initialData,
}) => {
  const [experiences, setExperiences] = useState(
    initialData || [{ from: "", to: "", description: "" }]
  );
  const [validationErrors, setValidationErrors] = useState({});

  const handleAddExperience = () => {
    setExperiences([...experiences, { from: "", to: "", description: "" }]);
  };

  const handleRemoveExperience = (index) => {
    const newExperiences = [...experiences];
    newExperiences.splice(index, 1);
    setExperiences(newExperiences);
  };

  const handleUpdateExperience = (index, updatedExperience) => {
    const newExperiences = [...experiences];
    newExperiences[index] = updatedExperience;
    setExperiences(newExperiences);
  };

  const validate = () => {
    const newErrors = {};
    experiences.forEach((exp, index) => {
      if (!exp.from) newErrors[`from-${index}`] = "Start date is required";
      if (!exp.to) newErrors[`to-${index}`] = "End date is required";
      if (exp.from && exp.to && exp.from > exp.to)
        newErrors[`to-${index}`] = "End date must be after start date";
      if (!exp.description.trim())
        newErrors[`description-${index}`] = "Description is required";
    });
    setValidationErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      onSubmit(experiences);
    }
  };

  return (
    <Form>
      <Grid>
        <Grid.Column width={2} verticalAlign="middle" textAlign="left">
          <Button
            icon={<Icon name="arrow circle left" />}
            onClick={onPrevious}
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <div className="title-medium">
            Experience <span className="medium-text">(Optional)</span>
          </div>
          <div className="medium-text">Enter your Work Experience Details</div>
        </Grid.Column>
        <Grid.Column width={4} verticalAlign="middle" textAlign="right">
          <Button
            positive
            content="Submit"
            
            onClick={handleSubmit}
          />
        </Grid.Column>
      </Grid>
      <br />
      {experiences.map((experience, index) => (
        <ExperienceEntry
          key={index}
          experience={experience}
          onUpdate={handleUpdateExperience}
          onRemove={handleRemoveExperience}
          index={index}
          validationErrors={validationErrors}
        />
      ))}
      <Button
        type="button"
        onClick={handleAddExperience}
        icon
        labelPosition="left"
        color="green"
      >
        <Icon name="add" />
        Add Another Experience
      </Button>
    </Form>
  );
};
