import React, { useState } from "react";
import { Form, Icon, Image, Message, Segment } from "semantic-ui-react";
import { AccentButton } from "../../Custom/CustomButtons";
import { ASSETS } from "../../../Utils/Assets";
import * as firebase from "../../../Firebase/initializeFirebase";
import { closeModal } from "../../../Redux/Modals/ModalActions";

const LoginForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    if (!email) {
      errors.email = "Email is required";
    } else if (!regex.test(email)) {
      errors.email = "Invalid email format";
    }

    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }

    setErrors(errors);

    // Form is valid if the errors object is empty
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        await firebase.default
          .auth()
          .signInWithEmailAndPassword(email, password);
        props.closeModal();
      } catch (e) {}
      setLoading(false);
    } else {
      console.log("Form has errors");
    }
  };

  return (
    <Segment basic loading={loading}>
      <Image src={ASSETS.LOGIN_ILLUSTRATON} size="medium" centered />
      <br />
      <Form onSubmit={handleSubmit} noValidate>
        <Form.Input
          fluid
          label="Email"
          placeholder="Enter your email"
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={
            errors.email ? { content: errors.email, pointing: "below" } : null
          }
        />
        <Form.Input
          fluid
          label="Password"
          placeholder="Enter your password"
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={
            errors.password
              ? { content: errors.password, pointing: "below" }
              : null
          }
        />
        <br />
        <AccentButton
          icon={<Icon name="arrow circle right" />}
          type="submit"
          text="Login to PixelnBrush"
        />
      </Form>
      {Object.keys(errors).length > 0 && (
        <Message negative style={{ width: "100%" }}>
          <Message.Header>
            There were some errors with your submission
          </Message.Header>
          <ul>
            {Object.values(errors).map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </Message>
      )}
    </Segment>
  );
};

export default LoginForm;
