import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "semantic-ui-react";

export default function PrivacyPolicy() {
  return (
    <div style={{ display: "block", textAlign: "center" }}>
      <Helmet>
        <title>Privacy Policy - PixelnBrush</title>
        <meta
          name="description"
          content="PixelnBrush: Streamline your brand's creative scale-up by hiring top freelance design and creative experts. Discover a curated community of professional designers ready to elevate your projects. Whether you're a brand looking to hire or a designer seeking opportunities, register with us and experience seamless collaboration and growth."
        />
      </Helmet>
      <Container textAlign="left" style={{ padding: "2rem" }}>
        <div className="title-large" style={{ textAlign: "center" }}>
          Privacy Policy - PixelnBrush
        </div>
        <br />

        <h1 class="title-medium">Privacy Policy</h1>
        <p class="medium-text">
          At PixelnBrush, we are committed to maintaining the trust and confidence
          of our visitors to our web site. In particular, we want you to know
          that PixelnBrush is not in the business of selling, renting or trading
          email lists with other companies and businesses for marketing
          purposes. In this Privacy Policy, we’ve provided detailed information
          on when and why we collect your personal information, how we use it,
          and how we keep it secure.
        </p>

        <h2 class="title-medium">Collection and Use of Personal Information</h2>
        <p class="medium-text">
          Personal information means any information that may be used to
          identify you, such as your name, title, phone number, email address,
          or mailing address. In general, you can browse our web site without
          giving us any personal information. We use several products to analyze
          traffic to this web site in order to understand our visitors' needs
          and to continually improve our site for them. These tools collect only
          anonymous, aggregate statistics.
        </p>

        <h2 class="title-medium">Cookies</h2>
        <p class="medium-text">
          Like many other websites, the PixelnBrush website uses cookies. 'Cookies'
          are small pieces of information sent by an organization to your
          computer and stored on your hard drive to allow that website to
          recognize you when you visit. They collect statistical data about your
          browsing actions and patterns and do not identify you as an
          individual.
        </p>

        <h2 class="title-medium">Access to Your Personal Information</h2>
        <p class="medium-text">
          You are entitled to view, amend, or delete the personal information
          that we hold. Email your request to our data protection officer at
          [email protected]
        </p>

        <h2 class="title-medium">Changes to this Privacy Notice</h2>
        <p class="medium-text">
          We may update this privacy notice from time to time. You should check
          this page occasionally to ensure you are happy with any changes. This
          privacy policy was last updated on [Date].
        </p>

        <h2 class="title-medium">Security</h2>
        <p class="medium-text">
          We take precautions to protect your information. When you submit
          sensitive information via the website, your information is protected
          both online and offline. Only employees who need the information to
          perform a specific job (for example, billing or customer service) are
          granted access to personally identifiable information.
        </p>
      </Container>
    </div>
  );
}
