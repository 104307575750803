// React Component File: SpecializedExperts.js

import React from "react";
import "./SpecializedExperts.css"; // make sure to have this CSS file in the same directory
import { ASSETS } from "../../../Utils/Assets";

const expertsData = [
  {
    title: "Fashion Designers",
    rating: 4.8,
    images: [ASSETS.USER_1, ASSETS.USER_2, ASSETS.USER_3],
  },
  {
    title: "Graphic Designers",
    rating: 4.9,
    images: [ASSETS.USER_1, ASSETS.USER_2, ASSETS.USER_3],
  },
  {
    title: "Textile Directors",
    rating: 4.9,
    images: [ASSETS.USER_1, ASSETS.USER_2, ASSETS.USER_3],
  },
  {
    title: "Cad Designers",
    rating: 4.8,
    images: [ASSETS.USER_1, ASSETS.USER_2, ASSETS.USER_3],
  },
  {
    title: "Print Designers",
    rating: 4.9,
    images: [ASSETS.USER_1, ASSETS.USER_2, ASSETS.USER_3],
  },
  {
    title: "Packaging Designers",
    rating: 4.8,
    images: [ASSETS.USER_1, ASSETS.USER_2, ASSETS.USER_3],
  },
  {
    title: "Product Designers",
    rating: 4.9,
    images: [ASSETS.USER_1, ASSETS.USER_2, ASSETS.USER_3],
  },
  {
    title: "Visual Designers",
    rating: 4.9,
    images: [ASSETS.USER_1, ASSETS.USER_2, ASSETS.USER_3],
  },
];

const SpecializedExperts = () => (
  <div className="specialized-experts-container">
    <div className="subcontainer">
    <h2 className="heading-text">
      Specialized design and creative experts you can count on
    </h2>
    <div className="stats">
      <div className="stat">
        <div className="title-large">4.92/5</div>
        <div className="stat-label">
          Average rating for work with design and creative talent.
        </div>
      </div>
      <div className="stat" style={{marginRight:"20px"}}>
        <div className="title-large">184K+ Contracts</div>
        <div className="stat-label">
          Involving design and creative work in the past year.
        </div>
      </div>
      <div className="stat">
        <div className="title-large">468 Skills</div>
        <div className="stat-label">Represented by talent on PixelnBrush.</div>
      </div>
    </div>
    </div>
    <div className="cards-container">
      {expertsData.map((expert, idx) => (
        <div key={idx} className="expert-card">
          <div className="expert-images">
            {expert.images.map((image, index) => (
              <div
                key={index}
                className="image-wrapper"
                style={{ "--i": index }}
              >
                <img src={image} alt={`Expert ${index + 1}`} />
              </div>
            ))}
          </div>
          <div className="expert-info">
            <h3 className="expert-title">{expert.title}</h3>
            <div className="expert-rating">
              {expert.rating}/5 average rating
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default SpecializedExperts;
