const { PROJECT_MODE, TESTING, STAGING } = require("./Controller");

const _TEST_ASSETS = {
  
};

const _PROD_ASSETS = {
  
};

const TEST_MODE = PROJECT_MODE == TESTING || PROJECT_MODE == STAGING;

const iconsPrefix = "/assets/icons";
const imagesPrefix = "/assets/images";
const usersPrefix = "/assets/images/users";
const designersPrefix = "/assets/images/designers";

export const ASSETS = {
  LP_BANNER: "",
  SERVICES_BANNER: "",
  STOCK_PROFILE_PIC: "",
  BANNER_CONTACT:"",
  LOGO: `/logo.png`,
  LOGIN_ILLUSTRATON:imagesPrefix + "/login_illustration.jpg",
  USER_1: usersPrefix+"/1.jpg",
  USER_2: usersPrefix+"/2.jpg",
  USER_3: usersPrefix+"/3.jpg",
  HIRE_DESIGNER: imagesPrefix+"/hire_designer.jpg",
  DESIGNER_1: designersPrefix+'/designer_image_1.jpg',
  DESIGNER_2: designersPrefix+'/designer_image_2.jpg',
  DESIGNER_3: designersPrefix+'/designer_image_3.jpg',
  ABOUT_1:imagesPrefix+"/about_1.jpg",
  ABOUT_2:imagesPrefix+"/about_2.jpeg",
  ABOUT_4:imagesPrefix+"/about_4.jpeg",
  CONTACT_BANNER:imagesPrefix +"/contact_banner.jpeg",
  ABOUT_BANNER: imagesPrefix+"/about_banner.jpeg"
};
