import React, { Component } from "react";
import {
  Form,
  Segment,
  Header,
  Button,
  Icon,
  Message,
  Dropdown,
} from "semantic-ui-react";
import axios from "axios";
import { QUERY_MAIL_URL } from "../../../Utils/URLConstants";
import { validateEmail } from "../../../Utils/FieldValidation";
import { BRAND_NAME, USERS_TYPE } from "../../../Utils/Constants";
import { AccentButton } from "../../Custom/CustomButtons";

let warningStatus = false;
let resultMessage = "";
let sendingMessage = false;
let bookingDone = false;

const emptyState = {
  name: "",
  contact: "",
  email: "",
  userType: "",
  query: "",
};

export default class ContactForm extends Component {
  state = {
    name: "",
    contact: "",
    email: "",
    userType: "",
    query: "",
  };

  sendMessage = async (data) => {
    try {
      sendingMessage = true;
      resultMessage = "";
      this.setState({});
      await axios
        .post(QUERY_MAIL_URL, data)
        .then((res) => {
          resultMessage = res.data.result;
          bookingDone = res.data.status === 0;
          sendingMessage = false;
          warningStatus = false;
          this.setState(emptyState);
        })
        .catch((e) => {
          bookingDone = false;
          resultMessage = "There was an error Sending Message!";
          sendingMessage = false;
          this.setState({});
        });
    } catch (e) {
      resultMessage = "There was an error Sending Message!";
      sendingMessage = false;
      this.setState({});
    }
  };

  handleFieldChange = (e) => {
    warningStatus = false;
    resultMessage = "";
    e.preventDefault();
    let fieldName = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => {
      return {
        [fieldName]: value,
      };
    });
  };

  handleDropdownChange = (e, { name, value }) => {
    warningStatus = false;
    resultMessage = "";
    this.setState({
      [name]: value,
    });
  };

  handleFormSubmit = () => {
    const validation = this.checkFormFields();
    if (validation) {
      this.sendMessage({
        data: this.state,
      });
    } else {
      warningStatus = true;
      this.setState({});
    }
  };

  showWarning = (name) => {
    let fieldValue = this.state[name];
    if (warningStatus && fieldValue.trim() === "") {
      return true;
    }
    if (warningStatus && name === "email") {
      if (!validateEmail(fieldValue)) {
        return true;
      }
    }
    if (warningStatus && name === "contact") {
      if (fieldValue.length !== 10) {
        return true;
      }
    }
    return false;
  };

  checkFormFields = () => {
    let valid = true;
    const stateKeys = Object.keys(this.state);
    if (stateKeys.length > 0) {
      stateKeys.forEach((key) => {
        let value = this.state[key];
        if (value.trim() === "") {
          valid = false;
        }
        if (key === "email") {
          if (!validateEmail(value)) {
            valid = false;
          }
        }

        if (key === "contact") {
          if (value.length !== 10) {
            valid = false;
          }
        }
      });
    }

    return valid;
  };

  render() {
    return (
      <div id="booking-form">
        <Segment
          basic
          secondary={this.props.secondary ?? true}
          // style={{ margin: "2% 2%" }}

          loading={sendingMessage}
        >
          {bookingDone && (
            <div style={{ textAlign: "center" }}>
              <Icon
                name="check circle outline"
                size="massive"
                inverted={!(this.props.secondary ?? true)}
              />
              <Header as="h2" textAlign="center">
                {resultMessage}
              </Header>
              <Message info>
                We have received your Query. We'll get in touch with you shortly
                with a resolution!
              </Message>
            </div>
          )}
          {!bookingDone && (
            <Form
              warning={warningStatus}
              inverted={!(this.props.secondary ?? true)}
            >
              {warningStatus && (
                <Header color="red"> Please fill data correctly!</Header>
              )}

              <div className="title-large">
                We are here to answer your Queries!
              </div>
              <br />
              <div className="large-text">
                Thank you for considering {BRAND_NAME} Our dedicated team is
                here to assist you in any way possible. Whether you have a
                question, need assistance or simply want to share your feedback,
                we're here to listen and provide the best service possible.
              </div>
              <br />

              <Form.Group widths="equal">
                <Form.Field error={this.showWarning("name")}>
                  <label>Name</label>
                  <input
                    placeholder="Name"
                    type="text"
                    value={this.state.name}
                    name="name"
                    onChange={this.handleFieldChange}
                  />
                </Form.Field>
                <Form.Field error={this.showWarning("contact")}>
                  <label>Phone</label>
                  <input
                    placeholder="Phone"
                    type="number"
                    name="contact"
                    value={this.state.contact}
                    onChange={this.handleFieldChange}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field error={this.showWarning("email")}>
                  <label>Email</label>
                  <input
                    placeholder="Email"
                    type="text"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleFieldChange}
                  />
                </Form.Field>
                <Form.Field error={this.showWarning("userType")}>
                  <label>Who are you?</label>
                  <Dropdown
                    selection
                    placeholder="Select Service"
                    name="userType"
                    onChange={this.handleDropdownChange}
                    value={this.state.userType}
                    options={USERS_TYPE}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field error={this.showWarning("query")}>
                <label>Enter your Query</label>
                <textarea
                  placeholder="Describe in detail your Query!"
                  name="query"
                  rows="4"
                  value={this.state.query}
                  onChange={this.handleFieldChange}
                />
              </Form.Field>
              <br />
              <Form.Field>
                <AccentButton
                  text="Send Message"
                  onClick={() => {
                    this.handleFormSubmit();
                  }}
                />
              </Form.Field>
            </Form>
          )}
        </Segment>
      </div>
    );
  }
}
