import React, { Component } from "react";
import { Modal, Dimmer, Image, Grid } from "semantic-ui-react";
import { closeModal } from "../../../Redux/Modals/ModalActions";
import { connect } from "react-redux";
import RegisterForm from "./LoginRegisterForm";
import LoginForm from "./LoginForm";

const actions = {
  closeModal,
};

class LoginRegisterModal extends Component {
  render() {
    return (
      <div>
        <Dimmer />
        <Modal closeIcon="close" open={true} onClose={this.props.closeModal} size="tiny">
          <Modal.Content>
            <Modal.Description>
              <Grid columns="1" stackable divided>
                <Grid.Column verticalAlign="middle">
                  <LoginForm closeModal={this.props.closeModal} />
                </Grid.Column>
              </Grid>
            </Modal.Description>
            <div />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}


export default connect(null, actions)(LoginRegisterModal);

export const MODAL_LOGIN_REGISTER = "LoginRegisterModal";
